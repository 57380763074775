import React, { useState } from 'react';
import { ConnectSMSAdsModalProps } from './startCampaignModels';
import { CloseIcon } from '../../../assets';
import {
  ConnectFacebookAdCampaigns,
  ConnectFacebookAddId,
  ConnectFacebookAdId,
} from './connectFacebookAdsModalComponents';

const ConnectSMSAdsModal: React.FC<ConnectSMSAdsModalProps> = ({
  onClose,
  chosenFacebookAdAccountId,
  setChosenFacebookAdAccountId,
  selectedFacebookAdId,
  setSelectedFacebookAdId,
  selectedFacebookAdCampaignId,
  setSelectedFacebookAdCampaignId,
}) => {
  const [temporaryFacebookAdAccountId, setTemporaryFacebookAdAccountId] =
    useState<string | null>(chosenFacebookAdAccountId);
  const [temporaryFacebookAdId, setTemporaryFacebookAdId] = useState<
    string | null
  >(selectedFacebookAdId);
  const [temporaryFacebookAdCampaignId, setTemporaryFacebookAdCampaignId] =
    useState<string | null>(selectedFacebookAdCampaignId);

  const handleConfirm = () => {
    setSelectedFacebookAdCampaignId(temporaryFacebookAdCampaignId);
    setSelectedFacebookAdId(temporaryFacebookAdId);
    setChosenFacebookAdAccountId(temporaryFacebookAdAccountId);
    onClose();
  };

  return (
    <div className="d-flex-center w-100 h-100vh p-absolute top-0 left-0">
      <div className="d-flex-start-start w-30rem max-h-80vh overflow-auto z4 bg-white b-radius-0_5rem ">
        <div className="d-flex-start-start w-100">
          <div className="d-flex-row-space-between w-100 p-1rem bb-1-l-gray">
            <span className="ff-poppins fw-500 fs-0_875rem c-regular">
              Connect Facebook Ads:
            </span>
            <div className="d-flex-center icon-hovered" onClick={onClose}>
              <CloseIcon />
            </div>
          </div>
        </div>
        <div className="d-flex-start-start w-100 overflow-auto">
          <ConnectFacebookAddId
            chosenFacebookAdAccountId={temporaryFacebookAdAccountId}
            setChosenFacebookAdAccountId={setTemporaryFacebookAdAccountId}
            setSelectedFacebookAdId={setTemporaryFacebookAdId}
          />
          {temporaryFacebookAdAccountId && (
            <div className="d-flex-start-start w-100">
              <ConnectFacebookAdCampaigns
                chosenFacebookAdAccountId={temporaryFacebookAdAccountId}
                setSelectedFacebookAdCampaignId={
                  setTemporaryFacebookAdCampaignId
                }
                selectedFacebookAdCampaignId={temporaryFacebookAdCampaignId}
              />
              {temporaryFacebookAdCampaignId && (
                <ConnectFacebookAdId
                  adCampaignId={temporaryFacebookAdCampaignId}
                  selectedFacebookAdId={temporaryFacebookAdId}
                  setSelectedFacebookAdId={setTemporaryFacebookAdId}
                />
              )}
            </div>
          )}
          <div className="d-flex-row-space-between w-100 p-1rem">
            <button
              className="btn btn-cancel w-10rem h-2_375rem"
              onClick={onClose}
            >
              <span className="ff-poppins c-black fs-0_75rem fw-600">
                Close
              </span>
            </button>
            <button
              className="btn btn-primary w-17rem h-2_375rem"
              disabled={
                temporaryFacebookAdAccountId === null ||
                temporaryFacebookAdId === null
              }
              onClick={handleConfirm}
            >
              <span className="ff-poppins c-white fs-0_75rem fw-600">
                Confirm
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectSMSAdsModal;
