import React, { useState } from 'react';

import { useAppData } from '../../../../context/AppContext';
import { InfoIcon, RightUpArrowIcon } from '../../../../assets';
import { CampaignAdSelectionProps } from './campaignSettingsFormModels';
//import AdCampaignSelectionModal from '../AdCampaignSelectionModal';
import ConnectSocialMediaModal from '../ConnectSocialMediaModal';
import { useAuth } from '../../../../context/AuthContext';
import { InfoModal } from '../../../../components';
import ConnectFacebookAdsModal from '../ConnectFacebookAdsModal';
import ConnectInstagramAdsModal from '../ConnectInstagramAdsModal';
import ConnectSMSAdsModal from '../ConnectSMSAdsModal';

const CampaignAdSelection: React.FC<CampaignAdSelectionProps> = ({
  formikValues,
  isCampaignDisabled,
  //selectedFacebookCampaign,
  //setSelectedFacebookCampaign,
  chosenFacebookAdAccountId,
  setChosenFacebookAdAccountId,
  selectedFacebookAdId,
  setSelectedFacebookAdId,
  selectedFacebookAdCampaignId,
  setSelectedFacebookAdCampaignId,
  chosenInstagramAdAccountId,
  setChosenInstagramAdAccountId,
}) => {
  const { currentUser } = useAuth();
  const { setShowModalBackground } = useAppData();
  /*const [isAdCampaignSelectionModalOpen, setIsAdCampaignSelectionModalOpen] =
    useState(false);*/
  const [showConnectFacebookModal, setShowConnectFacebookModal] =
    useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = useState<string>('');
  const [showConnectFacebookAdsModal, setShowConnectFacebookAdsModal] =
    useState<boolean>(false);
  const [showConnectInstagramAdsModal, setShowConnectInstagramAdsModal] =
    useState<boolean>(false);
  const [showConnectSMSAdsModal, setShowConnectSMSAdsModal] =
    useState<boolean>(false);

  const isSelectedPlatformSMS = formikValues?.platforms
    ?.map((platform: string) => platform.toLowerCase())
    .includes('sms');
  const isSelectedPlatformFacebook = formikValues?.platforms
    ?.map((platform: string) => platform.toLowerCase())
    .includes('facebook');
  const isSelectedPlatformInstagram = formikValues?.platforms
    ?.map((platform: string) => platform.toLowerCase())
    .includes('instagram');

  /*const handleShowAdCampaignSelectionModal = () => {
    setShowModalBackground(true);
    setIsAdCampaignSelectionModalOpen(true);
  };*/

  const handleOpenFacebookConnectModal = () => {
    setShowModalBackground(true);
    setShowConnectFacebookModal(true);
  };
  const handleCloseConnectFacebook = () => {
    setShowConnectFacebookModal(false);
    setShowModalBackground(false);
  };

  /*const handleCloseAdCampaignSelectionModal = () => {
    setIsAdCampaignSelectionModalOpen(false);
    setShowModalBackground(false);
  };*/

  const handleCloseInfoModal = () => {
    setShowInfoModal('');
    setShowModalBackground(false);
  };

  const handleCloseConnectFacebookAds = () => {
    setShowConnectFacebookAdsModal(false);
    setShowModalBackground(false);
  };

  const handleOpenConnectFacebookAdsModal = () => {
    setShowConnectFacebookAdsModal(true);
    setShowModalBackground(true);
  };
  const handleCloseConnectInstagramAds = () => {
    setShowConnectInstagramAdsModal(false);
    setShowModalBackground(false);
  };
  const handleOpenConnectInstagramAds = () => {
    setShowConnectInstagramAdsModal(true);
    setShowModalBackground(true);
  };

  const handleOpenConnectSMSAds = () => {
    setShowModalBackground(true);
    setShowConnectSMSAdsModal(true);
  };

  const handleCloseConnectSMSAds = () => {
    setShowConnectSMSAdsModal(false);
    setShowModalBackground(false);
  };

  const handleSelectAdModal = () => {
    if (isCampaignDisabled) {
      return;
    } else if (formikValues?.platforms?.length === 0) {
      setShowInfoModal('Select a Campaign Platform to continue');
      setShowModalBackground(true);
    } else if (isSelectedPlatformSMS && !currentUser?.isFacebookLoginIn) {
      handleOpenFacebookConnectModal();
    } else if (isSelectedPlatformSMS && currentUser?.isFacebookLoginIn) {
      handleOpenConnectSMSAds();
    } else if (isSelectedPlatformFacebook && !currentUser?.isFacebookLoginIn) {
      handleOpenFacebookConnectModal();
    } else if (isSelectedPlatformFacebook && currentUser?.isFacebookLoginIn) {
      handleOpenConnectFacebookAdsModal();
    } else if (isSelectedPlatformInstagram && !currentUser?.isFacebookLoginIn) {
      handleOpenFacebookConnectModal();
    } else if (isSelectedPlatformInstagram && currentUser?.isFacebookLoginIn) {
      handleOpenConnectInstagramAds();
    }
  };

  return (
    <>
      <div className="d-flex-start-start w-100 p-relative ">
        <div className="d-flex-row">
          <label className="campaign-label-input">Ad Campaign Selection</label>
          <div className="d-flex-center icon-hovered ml-0_5rem pb-0_5rem">
            <InfoIcon />
          </div>
        </div>
        <div
          className={`${!isCampaignDisabled ? 'pointer' : 'opacity-0_7'} d-flex-row campaign-input w-100`}
          onClick={handleSelectAdModal}
        >
          <span>
            {isSelectedPlatformSMS && selectedFacebookAdId
              ? 'Ad Selected'
              : isSelectedPlatformFacebook && selectedFacebookAdId
                ? 'Ad Selected'
                : isSelectedPlatformInstagram && chosenInstagramAdAccountId
                  ? 'Ad Selected'
                  : 'No Ad Campaign selected'}
          </span>
          <div className="d-flex-center w-1rem h-1rem p-absolute top-2_65rem right-0_625rem ">
            <RightUpArrowIcon className="top-2_4rem" />
          </div>
        </div>
      </div>
      {showInfoModal && (
        <div className="d-flex-center p-fixed bg-modal top-0 left-0 size-max z4">
          <InfoModal title={showInfoModal} onClose={handleCloseInfoModal} />
        </div>
      )}
      {/*isAdCampaignSelectionModalOpen && (
        <AdCampaignSelectionModal
          selectedFacebookCampaign={selectedFacebookCampaign}
          setSelectedFacebookCampaign={setSelectedFacebookCampaign}
          closeModal={handleCloseAdCampaignSelectionModal}
        />
      )*/}
      {showConnectFacebookModal && (
        <ConnectSocialMediaModal
          onClose={handleCloseConnectFacebook}
          isFacebook={true}
        />
      )}
      {showConnectFacebookAdsModal && (
        <ConnectFacebookAdsModal
          onClose={handleCloseConnectFacebookAds}
          chosenFacebookAdAccountId={chosenFacebookAdAccountId}
          setChosenFacebookAdAccountId={setChosenFacebookAdAccountId}
          selectedFacebookAdId={selectedFacebookAdId}
          setSelectedFacebookAdId={setSelectedFacebookAdId}
          selectedFacebookAdCampaignId={selectedFacebookAdCampaignId}
          setSelectedFacebookAdCampaignId={setSelectedFacebookAdCampaignId}
        />
      )}
      {showConnectInstagramAdsModal && (
        <ConnectInstagramAdsModal
          onClose={handleCloseConnectInstagramAds}
          chosenFacebookAdAccountId={chosenFacebookAdAccountId}
          setChosenFacebookAdAccountId={setChosenFacebookAdAccountId}
          selectedFacebookAdId={selectedFacebookAdId}
          setSelectedFacebookAdId={setSelectedFacebookAdId}
          selectedFacebookAdCampaignId={selectedFacebookAdCampaignId}
          setSelectedFacebookAdCampaignId={setSelectedFacebookAdCampaignId}
          chosenInstagramAdAccountId={chosenInstagramAdAccountId}
          setChosenInstagramAdAccountId={setChosenInstagramAdAccountId}
        />
      )}
      {showConnectSMSAdsModal && (
        <ConnectSMSAdsModal
          onClose={handleCloseConnectSMSAds}
          chosenFacebookAdAccountId={chosenFacebookAdAccountId}
          setChosenFacebookAdAccountId={setChosenFacebookAdAccountId}
          selectedFacebookAdId={selectedFacebookAdId}
          setSelectedFacebookAdId={setSelectedFacebookAdId}
          selectedFacebookAdCampaignId={selectedFacebookAdCampaignId}
          setSelectedFacebookAdCampaignId={setSelectedFacebookAdCampaignId}
        />
      )}
    </>
  );
};

export default CampaignAdSelection;
