import React, { useEffect, useState } from 'react';
import moment from 'moment';

import ConversationCharts from './ConversationCharts';
import { useAuth } from '../../../context/AuthContext';
import { getStatisticsById } from '../../../core/requests/requests';
import { StatisticsProps } from '../../../core/types/_models';
import { ConversationChartsFunctionsProps } from './conversationModels';
import DateFilter from '../../../components/dropdown/DateFilter';

const ConversationChartsFunctions: React.FC<
  ConversationChartsFunctionsProps
> = ({ setChartFilters, chartFilters }) => {
  const { currentUser } = useAuth();
  const [activePeriod, setActivePeriod] = useState<
    'weekly' | 'monthly' | 'yearly'
  >('weekly');
  const [queryString, setQueryString] = useState<string>('');
  const [statisticsData, setStatisticsData] = useState<StatisticsProps[]>([]);
  const currentUserId = currentUser?.id;

  const fetchStatistics = async () => {
    try {
      if (currentUserId) {
        const { data } = await getStatisticsById(currentUserId + queryString);
        if (data) {
          setStatisticsData(data);
        }
      }
    } catch (error) {
      console.error('Error fetching statistics', error);
    }
  };

  useEffect(() => {
    const query = `?start=${chartFilters?.startDate}&end=${chartFilters?.endDate}`;
    setQueryString(query);
  }, [chartFilters]);

  useEffect(() => {
    if (queryString) {
      fetchStatistics();
    }
  }, [queryString]);

  return (
    <div className="d-flex-start-start w-100 bg-white p-1rem-1_25rem b-radius-1_25rem mb-2rem">
      <div className="d-flex-row-space-between w-100 pb-1_5rem">
        <h4 className="h4-primary">All Conversational Leads</h4>
        <DateFilter
          setDateFilter={setChartFilters}
          startDate={chartFilters?.startDate}
          endDate={chartFilters?.endDate}
        />
      </div>
      <ConversationCharts statisticsData={statisticsData} />
    </div>
  );
};

export default ConversationChartsFunctions;
