import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import {
  CampaignDataProps,
  CampaignSettingsProps,
  InitialSettingsFormikValuesProps,
} from './components/startCampaignModels';

import {
  connectFacebookAddToCampaign,
  connectInstagramAddToCampaign,
  createCampaign,
  getUser,
  importFilesByCampaignId,
  updateCampaign,
} from '../../core/requests/requests';
import {
  backgroundInformationValidationSchema,
  campaignLinkValidationSchema,
  campaignNameValidationSchema,
  goalAndFocusValidationSchema,
  platformValidationSchema,
} from '../auth/components/ValidationSchemaItems';
import { campaignViewValues } from '../../config/CampaignsConfig';
import { getFormattedStringFromDate } from '../../helpers/campaignHelpers';
import { useAppData } from '../../context/AppContext';
import { InfoModal } from '../../components';
import { FacebookLeadsProps } from '../../core/types/_models';
import { FileProcessor } from './components';
import {
  SelectCampaignTimezone,
  SelectLaunchCampaignDropdown,
  SelectCampaignDateRange,
  SelectCampaignAdPlatform,
  SelectCampaignAgents,
} from './components/campaignSettingsFormComponents';
import { useAuth } from '../../context/AuthContext';
import CampaignAdSelection from './components/campaignSettingsFormComponents/CampaignAdSelection';

const CampaignSettings: React.FC<CampaignSettingsProps> = ({
  setCampaignData,
}) => {
  const {
    setCurrentStartCampaignView,
    setShowModalBackground,
    setCurrentCampaign,
    currentCampaign,
  } = useAppData();
  const { currentUser, setCurrentUser, authLogin } = useAuth();

  const [chosenFacebookAdAccountId, setChosenFacebookAdAccountId] = useState<
    string | null
  >(null);
  const [selectedFacebookAdId, setSelectedFacebookAdId] = useState<
    string | null
  >(null);
  const [selectedFacebookAdCampaignId, setSelectedFacebookAdCampaignId] =
    useState<string | null>(null);
  const [chosenInstagramAdAccountId, setChosenInstagramAdAccountId] = useState<
    string | null
  >(null);
  const handleClearAds = () => {
    setChosenFacebookAdAccountId(null);
    setSelectedFacebookAdId(null);
    setSelectedFacebookAdCampaignId(null);
    setChosenInstagramAdAccountId(null);
  };

  const initialFormikValues: InitialSettingsFormikValuesProps = {
    name: currentCampaign?.name ?? '',
    startDate: currentCampaign?.startDate
      ? getFormattedStringFromDate(new Date(currentCampaign?.startDate))
      : getFormattedStringFromDate(new Date()),
    endDate: currentCampaign?.endDate
      ? getFormattedStringFromDate(new Date(currentCampaign?.endDate))
      : getFormattedStringFromDate(
          new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
        ),
    campaignLaunch: currentCampaign?.launchTime ?? '06:00:00',
    timezone: currentCampaign?.timeZone ?? -6,
    agentId: currentCampaign?.agentId ?? '',
    link: currentCampaign?.productLink ?? '',
    goalAndFocus: currentCampaign?.goals ?? '',
    backgroundInformation: currentCampaign?.backgroundInfo ?? '',
    platforms: currentCampaign?.platforms ?? [],
  };

  const [formikValues, setFormikValues] =
    useState<InitialSettingsFormikValuesProps>(initialFormikValues);
  const [showSuccessMessage, setShowSuccessMessage] = useState<string>('');
  const [selectedFacebookCampaign, setSelectedFacebookCampaign] =
    useState<FacebookLeadsProps | null>(null);
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [isCampaignDisabled, setIsCampaignDisabled] = useState<boolean>(false);
  const accessToken = authLogin?.accessToken;

  const fetchUserData = async () => {
    try {
      if (currentUser && accessToken) {
        const { data } = await getUser(currentUser?.id, accessToken);
        setCurrentUser(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (!currentCampaign || currentCampaign?.status === 'NEW') {
      setIsCampaignDisabled(false);
    } else {
      setIsCampaignDisabled(true);
    }
  }, [currentCampaign]);

  const handleShowUpdateModal = (modalText: string) => {
    setShowModalBackground(true);
    setShowSuccessMessage(modalText);
  };

  const handleCloseUpdateModal = () => {
    setShowModalBackground(false);
    setShowSuccessMessage('');
    //setCurrentStartCampaignView(campaignViewValues?.startCampaign);
  };

  const isSelectedPlatformSMS = formikValues?.platforms
    ?.map((platform: string) => platform.toLowerCase())
    .includes('sms');
  const isSelectedPlatformFacebook = formikValues?.platforms
    ?.map((platform: string) => platform.toLowerCase())
    .includes('facebook');
  const isSelectedPlatformInstagram = formikValues?.platforms
    ?.map((platform: string) => platform.toLowerCase())
    .includes('instagram');

  const handleSubmit = async (values: InitialSettingsFormikValuesProps) => {
    try {
      if (currentCampaign) {
        const updatedPayload: CampaignDataProps = {
          ...currentCampaign,
          name: values.name,
          agentId: formikValues?.agentId,
          startDate: formikValues?.startDate,
          endDate: formikValues?.endDate,
          launchTime: formikValues?.campaignLaunch,
          timeZone: formikValues?.timezone,
          productLink: values.link,
          goals: values.goalAndFocus,
          backgroundInfo: values.backgroundInformation,
          platforms: values?.platforms,
        };

        const fetchedCampaignData = await updateCampaign(updatedPayload);
        if (fetchedCampaignData?.data) {
          handleShowUpdateModal('Campaign updated successfully!');
          setTimeout(() => {
            setCampaignData(fetchedCampaignData?.data);
            setCurrentCampaign(fetchedCampaignData?.data ?? '');
            setCurrentStartCampaignView(campaignViewValues?.startCampaign);
            handleCloseUpdateModal();
          }, 1500);
        }
      } else {
        const upperCasePlatforms = values?.platforms.map((platform) =>
          platform?.toUpperCase()
        );
        const updatedPayload: CampaignDataProps = {
          agentId: formikValues?.agentId,
          phoneId: null,
          region: 'us',
          name: values.name,
          startDate: formikValues?.startDate,
          endDate: formikValues?.endDate,
          launchTime: formikValues?.campaignLaunch,
          timeZone: formikValues?.timezone,
          productLink: values.link,
          goals: values.goalAndFocus,
          backgroundInfo: values.backgroundInformation,
          type: '',
          platforms: upperCasePlatforms,
          importFacebookLeadsCampaignId:
            selectedFacebookCampaign?.campaignId ?? '',
        };

        if (isSelectedPlatformFacebook && !selectedFacebookAdId) {
          setShowModalBackground(true);
          handleShowUpdateModal('Select Facebook Ads to continue.');
        } else if (isSelectedPlatformInstagram && !chosenInstagramAdAccountId) {
          handleShowUpdateModal('Select Instagram Ads to continue.');
        } else {
          const fetchedCampaignData = await createCampaign(updatedPayload);
          if (fetchedCampaignData?.data) {
            setCampaignData(fetchedCampaignData?.data);
            setCurrentCampaign(fetchedCampaignData?.data ?? '');
            if (fetchedCampaignData?.data?.id && fileToUpload) {
              try {
                const { data } = await importFilesByCampaignId(
                  fileToUpload,
                  fetchedCampaignData?.data?.id
                );
              } catch (error) {
                console.error('Error uploading file:', error);
                handleShowUpdateModal(`Error uploading file: ${error}`);
              }
            }
            if (isSelectedPlatformSMS) {
              if (
                chosenFacebookAdAccountId &&
                fetchedCampaignData?.data?.id &&
                selectedFacebookAdId
              ) {
                try {
                  const { data } = await connectFacebookAddToCampaign(
                    fetchedCampaignData?.data?.id,
                    chosenFacebookAdAccountId,
                    selectedFacebookAdId
                  );
                  if (data) {
                    handleShowUpdateModal('Campaign created successfully');
                    setTimeout(() => {
                      handleCloseUpdateModal();
                      setCurrentStartCampaignView(
                        campaignViewValues?.startCampaign
                      );
                    }, 2000);
                  }
                } catch (error: any) {
                  const errorMessage =
                    error.response?.status === 400 && error.response?.data
                      ? `Failed to add ads to the campaign: ${error.response.data}`
                      : `Failed to add ads to the campaign: ${error.message || error}`;

                  handleShowUpdateModal(errorMessage);
                }
              }
            } else if (isSelectedPlatformFacebook) {
              if (
                chosenFacebookAdAccountId &&
                fetchedCampaignData?.data?.id &&
                selectedFacebookAdId
              ) {
                try {
                  const { data } = await connectFacebookAddToCampaign(
                    fetchedCampaignData?.data?.id,
                    chosenFacebookAdAccountId,
                    selectedFacebookAdId
                  );
                  if (data) {
                    handleShowUpdateModal('Campaign created successfully');
                    setTimeout(() => {
                      handleCloseUpdateModal();
                      setCurrentStartCampaignView(
                        campaignViewValues?.startCampaign
                      );
                    }, 2000);
                  }
                } catch (error: any) {
                  const errorMessage =
                    error.response?.status === 400 && error.response?.data
                      ? `Failed to add ads to the campaign: ${error.response.data}`
                      : `Failed to add ads to the campaign: ${error.message || error}`;

                  handleShowUpdateModal(errorMessage);
                }
              }
            } else if (isSelectedPlatformInstagram) {
              if (
                chosenFacebookAdAccountId &&
                fetchedCampaignData?.data?.id &&
                selectedFacebookAdId
              ) {
                try {
                  const { data } = await connectFacebookAddToCampaign(
                    fetchedCampaignData?.data?.id,
                    chosenFacebookAdAccountId,
                    selectedFacebookAdId
                  );
                  if (data) {
                    if (
                      chosenInstagramAdAccountId &&
                      fetchedCampaignData?.data?.id
                    ) {
                      const { data } = await connectInstagramAddToCampaign(
                        fetchedCampaignData?.data?.id,
                        chosenInstagramAdAccountId
                      );
                      if (data) {
                        handleShowUpdateModal('Campaign created successfully');
                        setTimeout(() => {
                          handleCloseUpdateModal();
                          setCurrentStartCampaignView(
                            campaignViewValues?.startCampaign
                          );
                        }, 2000);
                      }
                    }
                  }
                } catch (error: any) {
                  const errorMessage =
                    error.response?.status === 400 && error.response?.data
                      ? `Failed to add ads to the campaign: ${error.response.data}`
                      : `Failed to add ads to the campaign: ${error.message || error}`;

                  handleShowUpdateModal(errorMessage);
                }
              }
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validationSchema = Yup.object().shape({
    name: campaignNameValidationSchema,
    link: campaignLinkValidationSchema,
    platforms: platformValidationSchema,
    goalAndFocus: goalAndFocusValidationSchema,
    backgroundInformation: backgroundInformationValidationSchema,
  });

  return (
    <>
      <Formik
        initialValues={formikValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, isValid, setFieldValue }) => (
          <Form className="w-100 mt-1_5rem">
            <div className="d-flex-start-between-row w-100">
              <div className="d-flex-start-start w-100 mr-1rem mw-19rem">
                <div className="d-flex-start-start w-100 mb-1rem p-relative">
                  <label className="campaign-label-input">
                    Name of Campaign *
                  </label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    placeholder="First Property Viewing "
                    required
                    disabled={isCampaignDisabled}
                    className={`${errors?.name && !isCampaignDisabled ? 'error' : ''} ${isCampaignDisabled && 'opacity-0_7'} campaign-input w-100`}
                  />
                  {errors?.name && !isCampaignDisabled && (
                    <div className="form-error">{errors?.name}</div>
                  )}
                </div>
                <SelectCampaignDateRange
                  formikValues={formikValues}
                  setFormikValues={setFormikValues}
                  isCampaignDisabled={isCampaignDisabled}
                />
                <div className="d-flex-start-start-row w-100 mb-1rem">
                  <SelectLaunchCampaignDropdown
                    formikValues={formikValues}
                    setFormikValues={setFormikValues}
                    isCampaignDisabled={isCampaignDisabled}
                  />

                  <SelectCampaignTimezone
                    setFormikValues={setFormikValues}
                    formikValues={formikValues}
                    isCampaignDisabled={isCampaignDisabled}
                  />
                </div>
                <SelectCampaignAgents
                  formikValues={formikValues}
                  setFormikValues={setFormikValues}
                  errors={errors}
                  isCampaignDisabled={isCampaignDisabled}
                />
              </div>
              <div className="d-flex-start-start w-100 mr-1rem mw-19rem">
                <div className="d-flex-start-start w-100 mb-1rem p-relative">
                  <label className="campaign-label-input">
                    Link to be listed in conversations *
                  </label>
                  <Field
                    type="text"
                    id="link"
                    name="link"
                    placeholder="https:www.calendly.com/astreaux"
                    required
                    disabled={isCampaignDisabled}
                    className={`${errors?.name ? 'error' : ''} ${isCampaignDisabled && 'opacity-0_7'} campaign-input w-100`}
                  />
                  {errors?.link && !isCampaignDisabled && (
                    <div className="form-error">{errors?.link}</div>
                  )}
                </div>
                <div className="d-flex-start-start w-100 mb-1rem  p-relative">
                  <label className="campaign-label-input">
                    Goal and Focus of Conversation
                  </label>
                  <Field
                    type="text"
                    id="goalAndFocus"
                    name="goalAndFocus"
                    disabled={isCampaignDisabled}
                    placeholder="Book an appointment to an open house"
                    className={`${errors?.goalAndFocus ? 'error' : ''} ${isCampaignDisabled && 'opacity-0_7'} campaign-input w-100`}
                  />
                  {errors?.goalAndFocus && !isCampaignDisabled && (
                    <div className="form-error">{errors?.goalAndFocus}</div>
                  )}
                </div>
                {/*<SelectCampaignTone
                  formikValues={formikValues}
                  setFormikValues={setFormikValues}
                />*/}
                <SelectCampaignAdPlatform
                  formikValues={formikValues}
                  setFormikValues={setFormikValues}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  isCampaignDisabled={isCampaignDisabled}
                  handleClearAds={handleClearAds}
                />
                <CampaignAdSelection
                  formikValues={formikValues}
                  isCampaignDisabled={isCampaignDisabled}
                  selectedFacebookCampaign={selectedFacebookCampaign}
                  setSelectedFacebookCampaign={setSelectedFacebookCampaign}
                  chosenFacebookAdAccountId={chosenFacebookAdAccountId}
                  setChosenFacebookAdAccountId={setChosenFacebookAdAccountId}
                  selectedFacebookAdId={selectedFacebookAdId}
                  setSelectedFacebookAdId={setSelectedFacebookAdId}
                  selectedFacebookAdCampaignId={selectedFacebookAdCampaignId}
                  setSelectedFacebookAdCampaignId={
                    setSelectedFacebookAdCampaignId
                  }
                  chosenInstagramAdAccountId={chosenInstagramAdAccountId}
                  setChosenInstagramAdAccountId={setChosenInstagramAdAccountId}
                />
              </div>
              <div className="d-flex-start-start w-100 mw-19rem">
                <div className="d-flex-start-start w-100 mb-1rem p-relative">
                  <label className="campaign-label-input">
                    Background Information
                  </label>
                  <Field
                    as="textarea"
                    id="backgroundInformation"
                    name="backgroundInformation"
                    disabled={isCampaignDisabled}
                    className={`${errors?.backgroundInformation ? 'error' : ''} ${isCampaignDisabled && 'opacity-0_7'} campaign-textarea w-100`}
                    placeholder="The addresss is 123 Cloverfield lane. The open house starts at 5pm and ends at 7pm. Visitors must show up on time."
                  />
                  {errors?.backgroundInformation && !isCampaignDisabled && (
                    <div className="form-error">
                      {errors?.backgroundInformation}
                    </div>
                  )}
                </div>
                <button
                  type="submit"
                  className="btn-header-primary"
                  disabled={!isValid || isCampaignDisabled}
                >
                  <span>Apply</span>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <FileProcessor
        setFileToUpload={setFileToUpload}
        isCampaignDisabled={isCampaignDisabled}
      />
      {showSuccessMessage && (
        <InfoModal
          title={showSuccessMessage}
          onClose={handleCloseUpdateModal}
        />
      )}
    </>
  );
};

export default CampaignSettings;
