import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { InfoIcon, LogoWithTitlePNG, RocketIcon } from '../../../assets';
import { useAuth } from '../../../context/AuthContext';
import { formatTimestamp } from '../../../helpers/displayDataHelpers';
import { ConfirmModal } from '../../../components';
import { useAppData } from '../../../context/AppContext';
import { cancelSubscription, getUser } from '../../../core/requests/requests';
import SubscriptionPlansModal from './SubscriptionPlansModal';

const PaymentsCurrentPlan: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser, authLogin, setCurrentUser } = useAuth();
  const { setShowModalBackground } = useAppData();
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] =
    useState(false);
  const [showSubscriptionPlans, setShowSubscriptionPlans] =
    useState<boolean>(false);
  const currentSubscription = currentUser?.subscription;
  const userId = authLogin?.userId;
  const accessToken = authLogin?.accessToken;

  const handleOpenDeleteSubscriptionModal = () => {
    setShowCancelSubscriptionModal(true);
    setShowModalBackground(true);
  };

  const handleCloseCancelSubscriptionModal = () => {
    setShowCancelSubscriptionModal(false);
    setShowModalBackground(false);
  };

  const handleCloseSubscriptionPlansModal = () => {
    setShowSubscriptionPlans(false);
    setShowModalBackground(false);
  };

  const handleOpenSubscriptionPlansModal = () => {
    setShowSubscriptionPlans(true);
    setShowModalBackground(true);
  };

  const handleFetchSubscription = async () => {
    try {
      const response = await cancelSubscription();
      if (response?.status === 204 && userId && accessToken) {
        const { data } = await getUser(userId, accessToken);
        if (data) {
          setCurrentUser(response.data);
          navigate('/select-subscription');
          handleCloseCancelSubscriptionModal();
        }
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="d-flex-start-start w-100 bg-white shadow-box-9 b-radius-0_5rem p-1_5rem mb-0_25rem">
        <div className="d-flex-start-start-row pb-1_5rem bb-1 w-100">
          <div className="d-flex-center mr-0_5rem">
            <img alt="" src={LogoWithTitlePNG} className="w-15rem" />
          </div>
          <div className="bg-e0effd p-0_375rem-0_75rem b-radius-0_375rem mt-0_375rem">
            <span className="ff-inter fw-500 fs-0_875rem c-1e429f">
              {currentUser?.subscriptionStatus?.status}
            </span>
          </div>
        </div>
        <div className="d-flex-row-space-between w-100 p-1rem-0 bb-1 mb-1_5rem">
          <span className="ff-inter c-6b7280">{`Renewal Date: ${formatTimestamp(currentUser?.subscriptionStatus?.endDateTime ?? 0)}`}</span>
          <span className="ff-inter c-111928 fw-600 fs-1_25rem">{`$${currentSubscription?.subscriptionPlan?.price}/${currentSubscription?.subscriptionPlan?.interval}`}</span>
        </div>
        <div className="d-flex-start-start w-100 mb-1_5rem">
          <div className="d-flex-row mb-1rem">
            <span className="ff-inter c-111928 fw-700 fs-1_25rem">
              {`${currentSubscription?.subscriptionPlan?.name} plan benefits`}
            </span>
            {/*<div className="d-flex-center ml-0_5rem icon-hovered">
              <InfoIcon />
            </div>*/}
          </div>
          {currentSubscription?.subscriptionPlan?.features.map(
            (feature, index) => {
              // Check if it's the first feature in a pair
              const isFirstInPair = index % 2 === 0;
              return (
                <div className="d-flex-start-start w-100" key={index}>
                  {isFirstInPair &&
                  index + 1 <
                    currentSubscription?.subscriptionPlan?.features.length ? (
                    <div className="d-flex-start-start-row w-100 mt-0_5rem">
                      <div className="d-flex-start-start-row w-50 pr-0_5rem">
                        <div className="d-flex-center mr-0_5rem">
                          <span className="fw-500 c-0e9f6e fs-0_875rem lh-1_375rem">
                            ✓
                          </span>
                        </div>
                        <span className="ff-inter fs-0_875rem c-6b7280 lh-1_375rem">
                          {feature.name}: {feature.description}
                        </span>
                      </div>
                      <div className="d-flex-start-start-row w-50">
                        <div className="d-flex-center mr-0_5rem">
                          <span className="fw-500 c-0e9f6e fs-0_875rem lh-1_375rem">
                            ✓
                          </span>
                        </div>
                        <span className="ff-inter fs-0_875rem c-6b7280 lh-1_375rem">
                          {
                            currentSubscription?.subscriptionPlan?.features[
                              index + 1
                            ].name
                          }
                          :{' '}
                          {
                            currentSubscription?.subscriptionPlan?.features[
                              index + 1
                            ].description
                          }
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              );
            }
          )}
        </div>
        <div className="d-flex-row">
          <button
            className="btn-subscriptions-secondary mr-1rem w-11rem"
            onClick={handleOpenDeleteSubscriptionModal}
          >
            Cancel subscription
          </button>
          <button
            className="btn-subscriptions-primary w-11rem"
            onClick={handleOpenSubscriptionPlansModal}
          >
            <div className="d-flex-center mr-0_5rem">
              <RocketIcon />
            </div>
            <span className="">Upgrade to PRO</span>
          </button>
        </div>
      </div>
      {showCancelSubscriptionModal && (
        <div className="d-flex-center p-fixed top-0 left-0 size-max z4">
          <ConfirmModal
            title="Are you sure you want to cancel subscription?"
            onConfirm={handleFetchSubscription}
            onCancel={handleCloseCancelSubscriptionModal}
          />
        </div>
      )}
      {showSubscriptionPlans && (
        <div className="d-flex-center p-fixed top-0 left-0 size-max z4">
          <SubscriptionPlansModal
            closeModal={handleCloseSubscriptionPlansModal}
          />
        </div>
      )}
    </>
  );
};

export default PaymentsCurrentPlan;
