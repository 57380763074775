import React, { useEffect, useState, useRef } from 'react';

import { CampaignNameFilterProps } from './conversationModels';
import { getCampaigns } from '../../../core/requests/requests';
import useOutsideClick from '../../../hooks/useOutsideClicks';
import { CampaignDataProps } from '../../startCampaign/components/startCampaignModels';
import { CloseIcon } from '../../../assets';

const CampaignNameFilter: React.FC<CampaignNameFilterProps> = ({
  setTemporaryFilters,
}) => {
  const [campaignData, setCampaignData] = useState<CampaignDataProps[]>([]);
  const [selectedCampaignsData, setSelectedCampaignsData] = useState<
    CampaignDataProps[]
  >([]);
  const [filteredCampaignsData, setFilteredCampaignsData] = useState<
    CampaignDataProps[]
  >([]);
  const [showCampaignsList, setShowCampaignsList] = useState<boolean>(false);
  const [campaignNameSearchValue, setCampaignNameSearchValue] =
    useState<string>('');

  const showCampaignsListRef = useRef<HTMLDivElement>(null);
  const excludeOutsideClickRef = useRef<HTMLDivElement>(null);

  const handleTriggerCampaignNameDropdown = () => {
    setShowCampaignsList(!showCampaignsList);
  };
  useOutsideClick(
    showCampaignsListRef,
    () => handleTriggerCampaignNameDropdown(),
    excludeOutsideClickRef
  );

  const fetchCampaigns = async () => {
    try {
      const { data } = await getCampaigns();
      if (data) {
        // Extract the names from the campaigns and set them to campaignNames
        setCampaignData(data);
        setFilteredCampaignsData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  useEffect(() => {
    if (campaignNameSearchValue?.trim()) {
      const filterText = campaignNameSearchValue
        .toLowerCase()
        .replace(/[^a-z0-9]/g, ''); // Normalize search text

      const filtered = campaignData
        .filter(({ name }) =>
          name
            ?.toLowerCase()
            .replace(/[^a-z0-9]/g, '')
            .includes(filterText)
        )
        .filter(
          ({ id }) =>
            !selectedCampaignsData.some((selected) => selected.id === id)
        ); // Exclude selected campaigns

      setFilteredCampaignsData(filtered);
    } else {
      setFilteredCampaignsData(
        campaignData.filter(
          ({ id }) =>
            !selectedCampaignsData.some((selected) => selected.id === id)
        )
      );
    }
  }, [campaignNameSearchValue, campaignData, selectedCampaignsData]);

  const handleSelectDropdownValue = (campaign: CampaignDataProps) => {
    if (campaign?.id) {
      setTemporaryFilters((prevFilters) => ({
        ...prevFilters,
        campaignId: [...(prevFilters.campaignId || []), campaign.id].filter(
          Boolean
        ) as string[],
      }));
    }
    setSelectedCampaignsData((prevSelected) => [...prevSelected, campaign]);
    setShowCampaignsList(false);
  };
  const handleRemoveCampaign = (campaign: CampaignDataProps) => {
    setSelectedCampaignsData((prevSelected) =>
      prevSelected.filter((selected) => selected.id !== campaign.id)
    );
    setTemporaryFilters((prevFilters) => ({
      ...prevFilters,
      campaignId: prevFilters.campaignId?.filter(
        (id) => id !== campaign.id
      ) as string[],
    }));
  };

  return (
    <div className="d-flex-start-start w-100 pb-2rem bb-1 p-relative">
      <div className="d-flex-row-space-between w-100 mb-0_75rem">
        <h4 className="h4-primary">Campaign name</h4>
      </div>
      <div
        className={`${showCampaignsList ? 'b-1-active bg-white' : 'b-1'} d-flex-row-space-between w-100 min-h-2_375rem b-radius-0_75rem p-0-1rem`}
      >
        <div className="d-flex-row max-w-15rem f-wrap">
          {selectedCampaignsData?.length > 0 ? (
            <>
              {selectedCampaignsData.map((campaign) => (
                <button
                  className="tag-button m-0_25rem  no-wrap"
                  key={campaign?.id}
                >
                  <span className="c-main fs-0_875rem pr-0_5rem">
                    {campaign?.name}
                  </span>
                  <div
                    className="d-flex-center icon-hovered b-1 b-radius-0_25rem"
                    onClick={() => handleRemoveCampaign(campaign)}
                  >
                    <CloseIcon className="w-1rem h-1rem" />
                  </div>
                </button>
              ))}
            </>
          ) : (
            <div className="d-flex-row">
              <span className="ff-roboto c-secondary fs-0_875rem ls-0_01rem">
                Campaign name
              </span>
            </div>
          )}
        </div>
        {!showCampaignsList && (
          <div
            className="d-flex-center"
            onClick={() => handleTriggerCampaignNameDropdown()}
          >
            <span
              className="ff-poppins fw-500 c-0062ff fs-0_75rem hovered"
              ref={excludeOutsideClickRef}
            >
              + ADD
            </span>
          </div>
        )}
      </div>
      {showCampaignsList && (
        <div
          className="p-absolute left-0 top-4_7rem z1 bg-white mw-12rem w-100 max-h-15rem overflow-auto shadow-box-12"
          ref={showCampaignsListRef}
        >
          <input
            className="converstaion-name-filter-input"
            placeholder="Search by campaign name"
            name="campaignName"
            value={campaignNameSearchValue}
            onChange={(e) => setCampaignNameSearchValue(e.target.value)}
            autoComplete="off"
          />
          {filteredCampaignsData?.length > 0 ? (
            <>
              {filteredCampaignsData.map((campaign, index) => (
                <div
                  className="d-flex-row-space-between w-100 h-2_375rem bt-1 pr-1rem"
                  key={index}
                >
                  <div className="d-flex-row max-w-15rem overflow-auto">
                    <span className="sort-dropdown-item pl-0_75rem">
                      {campaign?.name}
                    </span>
                  </div>
                  <button
                    className="btn btn-primary h-1_5rem d-flex-center w-3_81rem"
                    onClick={() => handleSelectDropdownValue(campaign)}
                  >
                    <span className="">+ ADD</span>
                  </button>
                </div>
              ))}
            </>
          ) : (
            <div className="d-flex-center-start h-2_375rem bt-1 hovered">
              <span className="sort-dropdown-item pl-0_75rem">
                There are no matching campaigns
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CampaignNameFilter;
