import React, { useState, useEffect } from 'react';
import {
  CopyIcon,
  EmptyHighlightsIcon,
  SampleBackgroundPNG,
} from '../../../assets';
import { ConversationHighlightsProps } from './conversationModels';
import { getChatSummary } from '../../../core/requests/requests';
import { ChatHighlightsProps } from '../../../core/types/_models';

const ConversationHighlights: React.FC<ConversationHighlightsProps> = ({
  chatById,
}) => {
  const [copiedMessage, setCopiedMessage] = useState<string>('');
  const [chatHighlights, setChatHighlights] =
    useState<ChatHighlightsProps | null>(null);
  const chatId = chatById?.chat?.id;

  useEffect(() => {
    const fetchChatHighlights = async () => {
      try {
        if (chatId) {
          const { data } = await getChatSummary(chatId);
          if (data) {
            setChatHighlights(data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchChatHighlights();
  }, [chatId]);

  const copyToClipboard = () => {
    if (chatHighlights?.summary) {
      navigator.clipboard.writeText(chatHighlights.summary).then(
        () => {
          setCopiedMessage('Copied!');
          setTimeout(() => setCopiedMessage(''), 2000);
        },
        (err) => {
          console.error('Could not copy text: ', err);
        }
      );
    }
  };

  return (
    <div className="d-flex-start-center w-40 p-relative pt-8rem">
      <div className="d-flex-start-start w-100 h-16rem p-absolute top-0 left-0 z0">
        <img alt="" src={SampleBackgroundPNG} className="w-100" />
      </div>
      <div className="d-flex-start-start bg-white z1 b-radius-1_5rem p-0-1_875rem w-calc-100_minus_8rem mw-15rem">
        <div className="d-flex-row-space-between w-100 p-2_375rem-0 bb-1">
          <span className="ff-poppins c-regular fw-600 fs-1_125rem">
            Conversation Highlights
          </span>
          {chatHighlights?.summary && (
            <div
              className="d-flex-center icon-hovered p-relative"
              onClick={copyToClipboard}
            >
              <CopyIcon />
              {copiedMessage && (
                <div className="p-absolute top-_-2_5rem right-_-2rem  bg-white shadow-box-9 b-radius-0_5rem p-0_25rem-1rem ">
                  <span className="text-center poppins-regular">
                    {copiedMessage}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="d-flex-center w-100 p-2_625rem-0">
          <div className="d-flex-center mb-2rem">
            <EmptyHighlightsIcon />
          </div>
          <div className="d-flex-center mb-3_5rem w-100">
            <span className="ta-justify poppins-regular fs-0_875rem">
              {chatHighlights?.summary
                ? chatHighlights?.summary
                : 'We don’t have any insights about this conversation'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationHighlights;
