import React from 'react';

import { ConversationsListProps } from './conversationModels';
import {
  ConversationListFilterDropdown,
  ConversationListSortDropdown,
} from './';
import DateFilter from '../../../components/dropdown/DateFilter';

const ConversationsList: React.FC<ConversationsListProps> = ({
  setChats,
  setFilters,
  filters,
  chats,
}) => {
  return (
    <div className="d-flex-row-space-between w-100 mb-1_5rem">
      <div className="d-flex-row">
        <ConversationListFilterDropdown
          setFilters={setFilters}
          filters={filters}
        />
        <DateFilter
          startDate={filters?.startDate}
          endDate={filters?.endDate}
          setDateFilter={setFilters}
        />
      </div>
      <ConversationListSortDropdown setChats={setChats} chats={chats} />
    </div>
  );
};

export default ConversationsList;
