import React, { useState, useRef } from 'react';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { TriangleIcon } from '../../assets';
import { DashboardDropdownsConfig } from '../../config/dashboardConfig';
import useOutsideClick from '../../hooks/useOutsideClicks';
import { DateFilterProps } from '../../pages/conversations/components/conversationModels';

const DateFilter = <
  T extends { startDate: string; endDate: string; type?: string },
>({
  startDate,
  endDate,
  type,
  setDateFilter,
}: DateFilterProps<T>) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownState, setDropdownState] = useState('Last 30 days');
  const [isDateRangeSelectorOpen, setIsDateRangeSelectorOpen] = useState(false);

  // Temporary state for the date range selector
  const [tempStartDate, setTempStartDate] = useState<string>(startDate);
  const [tempEndDate, setTempEndDate] = useState<string>(endDate);

  const showDropdownRef = useRef<HTMLDivElement>(null);
  const excludeOutsideClickRef = useRef<HTMLDivElement>(null);

  useOutsideClick(
    showDropdownRef,
    () => setShowDropdown(false),
    excludeOutsideClickRef
  );

  // Handle close date range selector
  const handleCloseDateRangeSelector = () => {
    setIsDateRangeSelectorOpen(false);
  };

  // Function to update the query string
  const updateQueryString = (start: string, end: string) => {
    setDateFilter((prevFilters) => ({
      ...prevFilters,
      startDate: start,
      endDate: end,
    }));
  };

  // Handle dropdown selection
  const handleDropdownSelect = (option: string) => {
    setDropdownState(option);
    setShowDropdown(false);

    let start, end, type;

    switch (option) {
      case 'Today':
        start = moment().format('YYYY-MM-DD');
        end = moment().format('YYYY-MM-DD');
        type = 'day';
        break;
      case 'Yesterday':
        start = moment().subtract(1, 'day').format('YYYY-MM-DD');
        end = moment().subtract(1, 'day').format('YYYY-MM-DD');
        type = 'day';
        break;
      case 'This Week':
        start = moment().startOf('week').format('YYYY-MM-DD');
        end = moment().endOf('week').format('YYYY-MM-DD');
        type = 'day';
        break;
      case 'Last week':
        start = moment()
          .subtract(1, 'week')
          .startOf('week')
          .format('YYYY-MM-DD');
        end = moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD');
        type = 'day';
        break;
      case 'Last 7 days':
        start = moment().subtract(6, 'days').format('YYYY-MM-DD'); // Including today
        end = moment().format('YYYY-MM-DD');
        type = 'day';
        break;
      case 'Last 14 days':
        start = moment().subtract(13, 'days').format('YYYY-MM-DD');
        end = moment().format('YYYY-MM-DD');
        type = 'day';
        break;
      case 'Last 28 days':
        start = moment().subtract(27, 'days').format('YYYY-MM-DD');
        end = moment().format('YYYY-MM-DD');
        type = 'day';
        break;
      case 'Last 30 days':
        start = moment().subtract(29, 'days').format('YYYY-MM-DD');
        end = moment().format('YYYY-MM-DD');
        type = 'day';
        break;
      case 'Last 60 days':
        start = moment().subtract(59, 'days').format('YYYY-MM-DD');
        end = moment().format('YYYY-MM-DD');
        type = 'day';
        break;
      case 'Custom Date Range':
        setIsDateRangeSelectorOpen(true);
        return; // Skip generating query string until custom date is selected
      default:
        return;
    }
    updateQueryString(start, end);
  };

  // Handle custom date selection
  const handleCustomDateChange = (ranges: any) => {
    const { startDate, endDate } = ranges.selection;
    const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
    const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

    setTempStartDate(formattedStartDate);
    setTempEndDate(formattedEndDate);
  };

  // Handle saving the selected custom date range
  const handleSaveDateRange = () => {
    updateQueryString(tempStartDate, tempEndDate);
    setIsDateRangeSelectorOpen(false);
  };

  return (
    <>
      <div className="d-flex-row p-relative">
        <span className="ff-inter c-secondary fs-1_125rem lh-1_125rem">
          Filter by time:{' '}
        </span>
        <div
          className="d-flex-row hovered"
          onClick={() => setShowDropdown(!showDropdown)}
          ref={excludeOutsideClickRef}
        >
          <span className="ff-inter c-main fs-1_125rem pl-0_375rem">
            {startDate && endDate && dropdownState === 'Custom Date Range'
              ? `${startDate} - ${endDate}`
              : dropdownState}
          </span>
          <div className="d-flex-center pl-0_375rem">
            <TriangleIcon
              className={`${showDropdown ? 'rotate-180deg' : ''} transition-0_5s`}
            />
          </div>
        </div>
        {showDropdown && (
          <div
            className="p-absolute left-_-0_5rem top-1_5rem z1 bg-white mw-12rem shadow-box-12"
            ref={showDropdownRef}
          >
            {DashboardDropdownsConfig.dashboardOptions.map((option, index) => (
              <div
                className="d-flex-center-start h-2_375rem bt-1 hovered"
                key={index}
                onClick={() => handleDropdownSelect(option)}
              >
                <span className="sort-dropdown-item pl-0_75rem">{option}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      {isDateRangeSelectorOpen && (
        <div className="d-flex-center p-absolute right-0 top-2_8rem z4 shadow-box-10 bg-white">
          <DateRange
            ranges={[
              {
                startDate: new Date(tempStartDate || startDate),
                endDate: new Date(tempEndDate || endDate),
                key: 'selection',
              },
            ]}
            onChange={handleCustomDateChange}
          />
          <div className="d-flex-row-space-between w-100 p-1rem">
            <button
              className="btn btn-secondary w-calc-50_minus_0_5rem"
              onClick={handleCloseDateRangeSelector}
            >
              Close
            </button>
            <button
              className="btn btn-primary w-calc-50_minus_0_5rem"
              onClick={handleSaveDateRange}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default DateFilter;
