import React, { useEffect, useState } from 'react';

import {
  LeadsFilterProps,
  LeadsFilterOptionsProps,
  LeadsTagsProps,
  LeadsFilterStateProps,
  LeadsFilterPlatformsProps,
} from '../../../core/types/_models';
import { getAllTags } from '../../../core/requests/requests';
import { useAuth } from '../../../context/AuthContext';
import { CloseIcon, SearchIcon } from '../../../assets';
import { useAppData } from '../../../context/AppContext';

const LeadsFilter: React.FC<LeadsFilterProps> = ({
  leadsState,
  setLeadsState,
  handleCloseFilter,
}) => {
  const { currentUser } = useAuth();
  const { leadsList } = useAppData();
  const [filterTags, setFilterTags] = useState<LeadsTagsProps[]>([]);
  const [availablePlatforms, setAvailablePlatforms] = useState<string[]>([]);
  const [filterOptions, setFilterOptions] = useState<LeadsFilterOptionsProps>({
    tags: [],
  });
  const [platformOptions, setPlatformOptions] =
    useState<LeadsFilterPlatformsProps>({
      platforms: [],
    });
  const [searchValue, setSearchValue] = useState<string>('');

  const userId = currentUser?.id;

  useEffect(() => {
    const fetchTags = async () => {
      try {
        if (userId) {
          const { data } = await getAllTags(userId);
          if (data) {
            setFilterTags(data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchTags();
  }, [userId]);

  // Function to handle changes in filter options
  const handleFilterChange = (tag: string) => {
    setFilterOptions((prevOptions: LeadsFilterOptionsProps) => {
      const newTags = prevOptions.tags.includes(tag)
        ? prevOptions.tags.filter((t) => t !== tag)
        : [...prevOptions.tags, tag];
      return { ...prevOptions, tags: newTags };
    });
  };

  // Filter tags based on the search input
  const filteredTags = filterTags.filter((tag) =>
    tag.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleApplyTags = () => {
    setLeadsState((previousData: LeadsFilterStateProps) => ({
      ...previousData,
      filterLeadsTags: filterOptions.tags,
      filterLeadsPlatforms: platformOptions.platforms,
    }));
    handleCloseFilter();
  };

  const handleClearFilters = () => {
    setFilterOptions({ tags: [] });
    setPlatformOptions({ platforms: [] });
    setLeadsState((previousData: LeadsFilterStateProps) => ({
      ...previousData,
      filterLeadsTags: [],
      filterLeadsPlatforms: [],
    }));
  };

  const handlePlatformChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setPlatformOptions((prevFilters) => ({
      ...prevFilters,
      platforms: checked
        ? [...prevFilters.platforms, name]
        : prevFilters.platforms.filter((platform) => platform !== name),
    }));
  };

  useEffect(() => {
    if (leadsState?.filterLeadsTags) {
      setFilterOptions({ tags: leadsState?.filterLeadsTags });
    }
    if (leadsState?.filterLeadsPlatforms) {
      setPlatformOptions({ platforms: leadsState?.filterLeadsPlatforms });
    }
  }, [leadsState?.filterLeadsTags, leadsState?.filterLeadsPlatforms]);

  useEffect(() => {
    if (leadsList?.length) {
      const uniqueOrigins = Array.from(
        new Set(leadsList.map((lead) => lead.origin).filter(Boolean))
      );
      setAvailablePlatforms(uniqueOrigins);
    }
  }, [leadsList]);

  return (
    <div className="d-flex-start-start pl-2_375rem pb-2rem h-100">
      <div className="d-flex-start-start w-20rem bg-white b-radius-1_125rem p-0_75rem">
        <div className="d-flex-row-space-between  w-100 mb-1_5rem mt-1rem">
          <h4 className="f-inter-secondary">Filters</h4>
          <div
            className="d-flex-center icon-hovered"
            onClick={handleCloseFilter}
          >
            <CloseIcon />
          </div>
        </div>
        {/* Filter options */}
        {/* Tags */}
        <div className="d-flex-start-start w-100 ">
          <div className="d-flex-row  mb-0_25rem">
            <h5 className="h5-primary-inter pb-0_5rem">Tags</h5>
          </div>
          <div className="d-flex-row w-100 mb-1rem p-relative">
            <input
              className="leads-search-input w-100"
              placeholder="Search tags"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)} // Update search input value
            />
            <div className="d-flex-center p-absolute top-0_5rem left-1rem">
              <SearchIcon />
            </div>
          </div>
          {/* Buttons for labels */}
          <div className="d-flex-row f-wrap w-100">
            {filteredTags.map((tag) => (
              <button
                key={tag.id}
                className={`btn-leads-filter-labels ${
                  filterOptions.tags.includes(tag.name) ? 'active' : ''
                }`}
                onClick={() => handleFilterChange(tag.name)}
              >
                {tag.name}
              </button>
            ))}
          </div>
        </div>
        <div className="d-flex-start-start w-100 pt-1_5rem pb-2rem">
          <div className="d-flex-row w-100 ">
            <h4 className="h5-primary-inter pb-0_5rem">Platforms</h4>
          </div>
          {availablePlatforms.map((platform) => (
            <div className="d-flex-row p-0_625rem-0" key={platform}>
              <input
                className="converstaion-filter-checkbox"
                type="checkbox"
                name={platform}
                checked={platformOptions.platforms.includes(platform)}
                onChange={handlePlatformChange}
              />
              <span className="f-roboto-primary pl-0_75rem fs-0_875rem">
                {platform}
              </span>
            </div>
          ))}
        </div>
        {/* Apply button */}
        <div className="d-flex-row ">
          <button
            className="btn-leads-filter mr-1rem"
            onClick={handleApplyTags}
          >
            Apply
          </button>

          <button
            className="btn-subscriptions-secondary w-5rem"
            onClick={handleClearFilters}
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
};

export default LeadsFilter;
