import React from 'react';
import { InfoIcon } from '../../assets';

interface TooltipProps {
  tooltipText: React.ReactNode; // Now accepts JSX
  tooltipWidth: number;
}

const Tooltip: React.FC<TooltipProps> = ({ tooltipText, tooltipWidth }) => {
  return (
    <div className="d-flex-center icon-hovered tooltip-container h-1rem">
      <InfoIcon />
      <span className="tooltip-text" style={{ width: `${tooltipWidth}px` }}>
        {tooltipText}
      </span>
    </div>
  );
};

export default Tooltip;
