import React, { useEffect, useRef, useState } from 'react';

import { ConversationFilterComponentProps } from './conversationModels';
import { CloseIcon } from '../../../assets';
import { ConversationFiltersProps } from '../../../core/types/_models';
import useOutsideClick from '../../../hooks/useOutsideClicks';

const ConversationFilter: React.FC<ConversationFilterComponentProps> = ({
  title,
  placeholder,
  values,
  name,
  setFilters,
}) => {
  const [showInput, setShowInput] = useState(false);
  const [currentInputValue, setCurrentInputValue] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const showInputRef = useRef<HTMLDivElement>(null);

  useOutsideClick(showInputRef, () => setShowInput(false));

  const handleShowInput = () => {
    setShowInput(true);
  };

  // When showInput changes to true, focus the input
  useEffect(() => {
    if (showInput && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showInput]);

  const handleAddValue = () => {
    if (!currentInputValue.trim()) {
      setShowInput(false);
      return;
    } // Avoid adding empty values
    setFilters((prev) => ({
      ...prev,
      // Cast name as keyof ConversationFiltersProps
      [name as keyof ConversationFiltersProps]: [
        ...(prev[name as keyof ConversationFiltersProps] as string[]),
        currentInputValue,
      ],
    }));
    setShowInput(false);
    setCurrentInputValue('');
  };

  const handleRemoveValue = (value: string) => {
    setFilters((prev) => ({
      ...prev,
      [name as keyof ConversationFiltersProps]: (
        prev[name as keyof ConversationFiltersProps] as string[]
      ).filter((item) => item !== value),
    }));
  };

  return (
    <div className="d-flex-start-start w-100 p-2rem-0 bb-1">
      <div className="d-flex-row-space-between w-100 mb-0_75rem">
        <h4 className="h4-primary">{title} </h4>
      </div>
      <div
        className={`${
          showInput ? 'b-1-active bg-white' : 'b-1'
        } d-flex-row-space-between w-100 min-h-2_375rem b-radius-0_75rem p-0-1rem`}
        onClick={() => values?.length === 0 && !showInput && handleShowInput()}
        ref={showInputRef}
      >
        <div
          className={`${showInput ? '' : 'max-w-15rem'} d-flex-row w-100  overflow-auto`}
        >
          <div className="d-flex-start-start w-100">
            <div
              className={`${showInput ? '' : 'pb-0_5rem'} d-flex-row w-100 f-wrap pt-0_5rem`}
            >
              {values?.length > 0 ? (
                <>
                  {values.map((value, index) => (
                    <button
                      className="tag-button m-0_25rem  no-wrap"
                      key={index}
                    >
                      <span className="c-main fs-0_875rem pr-0_5rem">
                        {value}
                      </span>
                      <div
                        className="d-flex-center icon-hovered b-1 b-radius-0_25rem"
                        onClick={() => handleRemoveValue(value)}
                      >
                        <CloseIcon className="w-1rem h-1rem" />
                      </div>
                    </button>
                  ))}
                </>
              ) : (
                <>
                  {!showInput && (
                    <div className="d-flex-row">
                      <span className="ff-roboto c-secondary fs-0_875rem ls-0_01rem">
                        {placeholder}
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
            {showInput && (
              <div
                className={`${values?.length > 0 && 'pt-0_2rem'} d-flex-row-space-between w-100 pb-0_5rem`}
              >
                <input
                  ref={inputRef}
                  className={`${values?.length > 0 ? ' converstaion-filter-input' : 'b-none outline-none'} `}
                  placeholder={placeholder}
                  name={name}
                  value={currentInputValue}
                  onChange={(e) => setCurrentInputValue(e.target?.value)}
                />
                <button
                  className="btn btn-primary h-1_5rem d-flex-center w-3_81rem ml-1rem"
                  onClick={handleAddValue}
                >
                  <span className="">+ ADD</span>
                </button>
              </div>
            )}
          </div>
        </div>
        {!showInput && (
          <div className="d-flex-center" onClick={handleShowInput}>
            <span className="ff-poppins fw-500 c-0062ff fs-0_75rem hovered">
              + ADD
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConversationFilter;
