import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';

import { ChevronDownIcon, InfoIcon } from '../../../assets';

import {
  cityValidationSchema,
  countryValidationSchema,
  emailValidationSchema,
  firstNameValidationSchema,
} from '../../auth/components/ValidationSchemaItems';
import { AgentPayloadProps } from '../../../core/types/_models';
import { createAgent, updateAgent } from '../../../core/requests/requests';
import { countryPhoneCodes } from '../../../config';
import { AddAgentBioModalProps } from './agentModels';
import useOutsideClick from '../../../hooks/useOutsideClicks';
import { SelectCampaignTone } from '../../startCampaign/components/campaignSettingsFormComponents';
import { CampaignToneDropdownConfig } from '../../../config/CampaignsConfig';
import { InfoModal } from '../../../components';

const AddAgentBioModal: React.FC<AddAgentBioModalProps> = ({
  onClose,
  updateAgentsList,
  agentId,
  agentData,
}) => {
  const [isToneDropdownOpen, setIsToneDropdownOpen] = useState(false);
  const [infoModalMessage, setInfoModalMessage] = useState<string>('');
  const toneDropdownRef = useRef<HTMLDivElement>(null);
  const excludeOutsideClickRef = useRef<HTMLDivElement>(null);

  useOutsideClick(
    toneDropdownRef,
    () => setIsToneDropdownOpen(false),
    excludeOutsideClickRef
  );
  const handleCloseInfoModal = () => {
    setInfoModalMessage('');
    onClose();
  };

  const toggleToneDropdown = () => {
    setIsToneDropdownOpen((prevState) => !prevState);
  };

  const handleChangeTone = (value: string) => {
    //setFieldValue('tone', value);
    setIsToneDropdownOpen(false);
  };
  const initialFormikValues: AgentPayloadProps = {
    name: agentData?.name ?? '',
    email: agentData?.email ?? '',
    title: agentData?.title ?? '',
    country: agentData?.country ?? '',
    city: agentData?.city ?? '',
    tone: agentData?.tone ?? '',
    biography: agentData?.biography ?? '',
    feedback: agentData?.feedback ?? '',
  };
  const showEditModalRef = useRef<HTMLDivElement>(null);

  useOutsideClick(showEditModalRef, () => onClose());

  const handleCreateAgent = async (values: AgentPayloadProps) => {
    try {
      const { data } = await createAgent(values);
      if (data) {
        updateAgentsList();
        onClose();
      }
    } catch (error: any) {
      setInfoModalMessage(error?.response?.data?.message);
      console.log(error);
    }
  };

  const handleUpdateAgent = async (
    agentId: string,
    values: AgentPayloadProps
  ) => {
    try {
      const { data } = await updateAgent(agentId, values);
      if (data) {
        updateAgentsList();
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (values: AgentPayloadProps) => {
    try {
      if (agentId) {
        handleUpdateAgent(agentId, values);
      } else {
        handleCreateAgent(values);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validationSchema = Yup.object().shape({
    name: firstNameValidationSchema,
    email: emailValidationSchema,
    country: countryValidationSchema,
    city: cityValidationSchema,
  });

  return (
    <>
      <div className="d-flex-center w-100 h-100vh p-absolute top-0 left-0">
        <div
          className="d-flex-start-start w-90 z4 bg-white p-1_5rem b-radius-0_5rem"
          ref={showEditModalRef}
        >
          <Formik
            initialValues={initialFormikValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ errors, isValid, values, setFieldValue }) => (
              <Form className="w-100">
                <div className="d-flex-row-space-between w-100 mb-0_5rem">
                  <div className="d-flex-row">
                    <span className="c-111928 ff-inter fw-700 fs-1_25rem mr-0_5rem">
                      General Agent Information
                    </span>
                    <div className="d-flex-center icon-hovered">
                      <InfoIcon />
                    </div>
                  </div>
                  <div className="d-flex-row">
                    <button
                      className="btn-agent-bio-cancel mr-0_5rem w-5rem"
                      onClick={() => onClose()}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn-agent-bio-save"
                      type="submit"
                      disabled={!isValid}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="d-flex-start-between-row w-100 h-32_65rem">
                  <div className="d-flex-start-start w-calc-33_minus_1rem ">
                    <div className="d-flex-start-start w-100 mb-1rem p-relative">
                      <label htmlFor="name" className="campaign-label-input">
                        Your name *
                      </label>
                      <Field
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Agent name"
                        required
                        className="campaign-input w-100"
                      />
                      {errors?.name && (
                        <div className="form-error">{errors?.name}</div>
                      )}
                    </div>
                    <div className="d-flex-start-start w-100 mb-1rem p-relative">
                      <label htmlFor="email" className="campaign-label-input">
                        Your email *
                      </label>
                      <Field
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Agent email"
                        required
                        className="campaign-input w-100"
                      />
                      {errors?.email && (
                        <div className="form-error">{errors?.email}</div>
                      )}
                    </div>
                    <div className="d-flex-start-start w-100 mb-1rem p-relative">
                      <label htmlFor="title" className="campaign-label-input">
                        Title
                      </label>
                      <Field
                        type="text"
                        id="title"
                        name="title"
                        placeholder="Title"
                        required
                        className="campaign-input w-100"
                      />
                    </div>

                    <div className="d-flex-start-start w-100 mb-1rem p-relative hovered">
                      <label htmlFor="country" className="campaign-label-input">
                        Country *
                      </label>
                      <Field
                        as="select"
                        id="country"
                        name="country"
                        required
                        className="campaign-input w-100 pr-1rem"
                      >
                        <option value="" label="Select a country" />{' '}
                        {/* Placeholder option */}
                        {countryPhoneCodes.map((country) => (
                          <option key={country.iso} value={country.country}>
                            {country.country}
                          </option>
                        ))}
                      </Field>
                      {errors?.country && (
                        <div className="form-error">{errors?.country}</div>
                      )}
                    </div>
                    <div className="d-flex-start-start w-100 mb-1rem p-relative">
                      <label htmlFor="city" className="campaign-label-input">
                        City *
                      </label>
                      <Field
                        type="text"
                        id="city"
                        name="city"
                        placeholder="City"
                        required
                        className="campaign-input w-100"
                      />
                      {errors?.city && (
                        <div className="form-error">{errors?.city}</div>
                      )}
                    </div>
                    {/*<label htmlFor="city" className="campaign-label-input">
                    Tone Selection
                  </label>
                  <Field
                    type="text"
                    id="tone"
                    name="tone"
                    placeholder="Tone Selection"
                    required
                    className="campaign-input w-100"
                  />*/}
                    <div className="d-flex-start-start w-100 p-relative mb-1rem">
                      <div className="d-flex-row">
                        <label className="campaign-label-input">
                          Tone Selection
                        </label>
                        <div className="d-flex-center icon-hovered ml-0_5rem pb-0_5rem">
                          <InfoIcon />
                        </div>
                      </div>
                      <div
                        className="d-flex-row campaign-input w-100 pointer"
                        onClick={toggleToneDropdown}
                        ref={excludeOutsideClickRef}
                      >
                        <span>{values?.tone || 'Select a tone'}</span>
                        <div className="d-flex-center p-absolute right-0_625rem top-2_8rem">
                          <ChevronDownIcon
                            className={`${isToneDropdownOpen && 't-rotate-180'}`}
                          />
                        </div>
                      </div>
                      {isToneDropdownOpen && (
                        <div
                          className="p-absolute z1 w-100 overflow-auto max-h-15rem top-4_5rem bg-white shadow-box-12"
                          ref={toneDropdownRef}
                        >
                          {CampaignToneDropdownConfig.map((option, index) => (
                            <div
                              key={index}
                              className={`${option === values?.tone && 'bg-badge'} d-flex-center-start h-2_375rem bb-1 hovered`}
                              onClick={() => {
                                setFieldValue('tone', option); // Update Formik state
                                setIsToneDropdownOpen(false); // Close dropdown
                              }}
                            >
                              <span className="pl-0_5rem f-roboto-m-primary fs-0_875rem">
                                {option}
                              </span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex-start-start w-calc-33_minus_1rem h-100">
                    <div className="d-flex-start-start w-100 mb-1rem h-100">
                      <label
                        htmlFor="biography"
                        className="campaign-label-input "
                      >
                        Agent Biography
                      </label>
                      <Field
                        as="textarea"
                        id="biography"
                        name="biography"
                        className="campaign-textarea w-100 h-100"
                        placeholder="Hello, my name is Bonnie Green..."
                      />
                    </div>
                  </div>
                  <div className="d-flex-start-start w-calc-33_minus_1rem h-100">
                    <div className="d-flex-start-start w-100 mb-1rem h-100">
                      <label
                        htmlFor="feedback"
                        className="campaign-label-input "
                      >
                        Agent Feedback
                      </label>
                      <Field
                        as="textarea"
                        id="feedback"
                        name="feedback"
                        className="campaign-textarea w-100 h-100"
                        placeholder="Sound more enthusiastic about our product..."
                      />
                      <span className="mt-0_5rem ff-inter c-6b7280 lh-1_125rem fs-0_75rem ">
                        Notes for the AI to learn how you want them to interact.
                      </span>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {infoModalMessage && (
        <div className="d-flex-center p-fixed bg-modal top-0 left-0 size-max z4">
          <InfoModal title={infoModalMessage} onClose={handleCloseInfoModal} />
        </div>
      )}
    </>
  );
};

export default AddAgentBioModal;
