import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ConversationCard,
  ConversationChartsFunctions,
  ConversationFilters,
  ConversationsHeader,
  ConversationsList,
} from './components';
import { getAllChats } from '../../core/requests/requests';
import {
  AllChatProps,
  ConversationFiltersProps,
} from '../../core/types/_models';
import moment from 'moment';
import { ChartFiltersProps } from './components/conversationModels';

const Conversations: React.FC = () => {
  const navigate = useNavigate();
  const [chats, setChats] = useState<AllChatProps[]>([]);
  const defaultStartDate = moment().subtract(29, 'days').format('YYYY-MM-DD');
  const defaultEndDate = moment().format('YYYY-MM-DD');
  const [filters, setFilters] = useState<ConversationFiltersProps>({
    campaignId: [],
    words: [],
    conversation: [],
    userName: [],
    platforms: [],
    status: '',
    startDate: defaultStartDate,
    endDate: defaultEndDate,
  });
  const [chartFilters, setChartFilters] = useState<ChartFiltersProps>({
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    type: 'day',
  });

  const handleNavigateToHighlights = (chatId: string) => {
    navigate(`/conversations/${chatId}`);
  };

  const handleGetAllChats = async () => {
    try {
      const { data } = await getAllChats(filters);
      if (data) {
        setChats(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleGetAllChats();
  }, [filters]);

  return (
    <div className="d-flex-start-start w-100 p-1_875rem">
      <ConversationsHeader chartFilters={chartFilters} />
      <div className="d-flex-start-start-row w-100">
        <ConversationFilters
          filters={filters}
          setFilters={setFilters}
          setChats={setChats}
          chats={chats}
        />
        <div className="d-flex-start-start w-calc-100_minus_20rem pl-2_5rem pt-2rem">
          <ConversationChartsFunctions
            chartFilters={chartFilters}
            setChartFilters={setChartFilters}
          />
          <div className="d-flex-start-start w-100">
            <ConversationsList
              setFilters={setFilters}
              filters={filters}
              setChats={setChats}
              chats={chats}
            />
            {chats?.length > 0 &&
              chats?.map((chat) => (
                <div
                  className="d-flex-start-start w-100 hovered"
                  onClick={() => handleNavigateToHighlights(chat?.id)}
                  key={chat?.id}
                >
                  <ConversationCard chat={chat} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Conversations;
