import React, { useState, useRef } from 'react';
import { SelectAdCampaignProps } from './campaignSettingsFormModels';
import { ChevronDownIcon, InfoIcon } from '../../../../assets';
import { CampaignPlatformDropdownConfig } from '../../../../config/CampaignsConfig';
import useOutsideClick from '../../../../hooks/useOutsideClicks';
import { useAuth } from '../../../../context/AuthContext';

const SelectCampaignAdPlatform: React.FC<SelectAdCampaignProps> = ({
  formikValues,
  setFormikValues,
  errors,
  setFieldValue,
  isCampaignDisabled,
  handleClearAds,
}) => {
  const { currentUser } = useAuth();
  const [isPlatformDropdownOpen, setIsPlatformDropdownOpen] = useState(false);

  const adPlatformDropdown = useRef<HTMLDivElement>(null);
  const excludeOutsideClickRef = useRef<HTMLDivElement>(null);

  useOutsideClick(
    adPlatformDropdown,
    () => setIsPlatformDropdownOpen(false),
    excludeOutsideClickRef
  );

  const togglePlatformDropdown = () => {
    setIsPlatformDropdownOpen((prevState) => !prevState);
  };

  const handleChangePlatform = (value: string) => {
    setFormikValues((prevState) => ({
      ...prevState,
      platforms: [value], // replace the entire platforms array with the new value
    }));
    handleClearAds();
    setIsPlatformDropdownOpen(false);
  };

  return (
    <div className="d-flex-start-start w-100 p-relative mb-1rem">
      <div className="d-flex-row">
        <label className="campaign-label-input">
          Communication Channel Selection
        </label>
        <div className="d-flex-center icon-hovered ml-0_5rem pb-0_5rem">
          <InfoIcon />
        </div>
      </div>
      <div
        className={`${
          errors?.platforms && !isCampaignDisabled && 'error'
        } ${isCampaignDisabled ? 'opacity-0_7' : 'pointer'} d-flex-row campaign-input w-100`}
        onClick={() =>
          !isCampaignDisabled ? togglePlatformDropdown() : () => {}
        }
        ref={excludeOutsideClickRef}
      >
        <span>{formikValues?.platforms[0]}</span>
        <div className="d-flex-center p-absolute right-0_625rem top-2_8rem">
          <ChevronDownIcon
            className={`${isPlatformDropdownOpen && 't-rotate-180'}`}
          />
        </div>
      </div>

      {errors?.platforms && !isCampaignDisabled && (
        <div className="form-error">{errors?.platforms}</div>
      )}
      {isPlatformDropdownOpen && (
        <div
          className="p-absolute z1 w-100 overflow-auto max-h-15rem top-4_5rem bg-white shadow-box-12"
          ref={adPlatformDropdown}
        >
          {CampaignPlatformDropdownConfig.map((option, index) => {
            // Check if SMS should be disabled for the current user
            const isSMSDisabled =
              option === 'SMS' &&
              currentUser?.subscription?.subscriptionPlan?.name !==
                'Pro Monthly';

            return (
              <div
                key={index}
                className={`${option === formikValues?.platforms[0] ? 'bg-badge' : ''} d-flex-center-start h-2_375rem bb-1 ${
                  !isSMSDisabled ? 'hovered' : ''
                }`}
                onClick={() => {
                  // Prevent SMS from being clickable if disabled
                  if (option === 'SMS' && isSMSDisabled) {
                    return;
                  }
                  if (
                    option === 'SMS' ||
                    option === 'Facebook' ||
                    option === 'Instagram'
                  ) {
                    handleChangePlatform(option);
                    setFieldValue('platforms', [option]);
                  }
                }}
              >
                <span
                  className={`pl-0_5rem fs-0_875rem ${
                    option === 'SMS' && isSMSDisabled
                      ? 'c-secondary'
                      : 'f-roboto-m-primary'
                  }`}
                >
                  {option}
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SelectCampaignAdPlatform;
