import React, { useEffect, useState } from 'react';

import {
  FacebookRoundIcon,
  HubspotRoundIcon,
  SettingsGearIcon,
} from '../../assets';
import { useAppData } from '../../context/AppContext';
import {
  getUser,
  loginToFacebook,
  loginToHubspot,
  logoutFromFacebook,
  logoutFromHubspot,
} from '../../core/requests/requests';
import { useAuth } from '../../context/AuthContext';
import SettingsHeader from './components/SettingsHeader';
import { ConfirmModal, InfoModal } from '../../components';
import { calculateDaysDifference } from '../../helpers/dateHelpers';

const SettingsIntegrations: React.FC = () => {
  const { currentUser, setCurrentUser, authLogin } = useAuth();
  const { setShowModalBackground } = useAppData();
  const [showConfirmFacebookLogoutModal, setShowConfirmFacebookLogoutModal] =
    useState(false);
  const [showConfirmHubspotLogoutModal, setShowConfirmHubspotLogoutModal] =
    useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<string>('');

  const accessToken = authLogin?.accessToken;

  const handleCloseFacebookLogoutModal = () => {
    setShowConfirmFacebookLogoutModal(false);
    setShowModalBackground(false);
  };

  const handleOpenFacebookLogoutModal = () => {
    setShowConfirmFacebookLogoutModal(true);
    setShowModalBackground(true);
  };

  const handleCloseHubspotLogoutModal = () => {
    setShowConfirmHubspotLogoutModal(false);
    setShowModalBackground(false);
  };

  const handleOpenHubspotLogoutModal = () => {
    setShowConfirmHubspotLogoutModal(true);
    setShowModalBackground(true);
  };

  const handleCloseSuccessModal = () => {
    setShowModalBackground(false);
    setShowSuccessMessage('');
  };

  const fetchUserData = async () => {
    try {
      if (currentUser && accessToken) {
        const { data } = await getUser(currentUser?.id, accessToken);
        setCurrentUser(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  /*const handleSyncLeads = () => {
    try {
      if (HUBSPOT_CLIENT_ID && HUBSPOT_REDIRECT_URI) {
        const authUrl =
          'https://app-eu1.hubspot.com/oauth/authorize' +
          `?client_id=${encodeURIComponent(HUBSPOT_CLIENT_ID)}` +
          `&scope=crm.lists.read%20crm.objects.contacts.read%20crm.schemas.contacts.read` +
          `&redirect_uri=${encodeURIComponent(HUBSPOT_REDIRECT_URI)}`;
        window.open(authUrl, '_blank', 'noopener,noreferrer'); // Open in a new tab
      } else {
        console.error('HubSpot Client ID or Redirect URI is missing.');
      }
    } catch (error) {
      console.error(
        'An unexpected error occurred while trying to sync leads:',
        error
      );
    }
  };*/

  const handleIntegrationToHubspot = async () => {
    if (currentUser) {
      try {
        const { data } = await loginToHubspot(currentUser?.id);
        //const { data } = await integrationWithHubspot(currentUser);
        if (data?.url) {
          window.open(data.url, '_blank'); // Open the URL in a new tab
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleIntegrationToFacebook = async () => {
    if (currentUser) {
      try {
        const { data } = await loginToFacebook(currentUser?.id);
        //const { data } = await integrationWithHubspot(currentUser);
        if (data?.loginUrl) {
          window.open(data.loginUrl, '_blank'); // Open the URL in a new tab
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  {
    /*const handleConnectFacebook = async () => {
    try {
      if (currentCampaign && currentCampaign?.id) {
        sessionStorage.setItem(
          'currentStartCampaignView',
          currentStartCampaignView
        );
        const { data } = await facebookIntegration(currentCampaign?.id);
        if (data && data.loginUrl) {
          window.location.href = data.loginUrl;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };*/
  }

  const handleLogoutFromFacebook = async () => {
    try {
      if (currentUser?.id) {
        const response = await logoutFromFacebook(currentUser?.id);
        if (response?.status === 200) {
          const daysLeft = calculateDaysDifference(response?.data?.endDate);

          // Determine the pluralization of "day/days"
          const dayText = daysLeft === 1 ? 'day' : 'days';

          fetchUserData();
          setShowConfirmFacebookLogoutModal(false);
          setShowSuccessMessage(
            `Facebook account disconnected successfully! ${daysLeft && `Your campaigns will be deactivated in ${daysLeft} ${dayText}.`}`
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogoutFromHubspot = async () => {
    try {
      if (currentUser?.id) {
        const response = await logoutFromHubspot(currentUser?.id);
        if (response?.status === 200) {
          fetchUserData();
          setShowConfirmHubspotLogoutModal(false);
          setShowSuccessMessage('Hubspot account disconnected successfully');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="d-flex-start-start w-100 p-1_875rem">
      <SettingsHeader />
      <div className="d-flex-start-start w-100 mt-1rem">
        <div className="d-flex-start-start mb-2_375rem">
          <span className="fw-600 c-111928 fs-1_125rem lh-1_125rem ff-poppins mb-0_5rem">
            Integrations
          </span>
        </div>
        <div className="d-flex-row-space-between w-100 mb-2rem">
          <div className="d-flex-start-start bg-white w-calc-50_minus_1rem b-radius-0_5rem shadow-box-9 p-1rem">
            <div className="d-flex-row-space-between w-100 mb-0_5rem">
              <div className="d-flex-row">
                <div className="d-flex-center mr-0_375rem">
                  <HubspotRoundIcon />
                </div>
                <span className="ff-poppins fw-600 c-111928">Hubspot</span>
              </div>
              {/*<div className="d-flex-center icon-hovered">
                <SettingsGearIcon />
              </div>*/}
            </div>
            <span className="ff-poppins fw-400 c-6b7280 fs-0_875rem lh-0_875rem mb-0_5rem">
              Online platform for sending professionals e-mails to mass
              recipients.
            </span>
            {currentUser?.isHubspotLoginIn ? (
              <button
                className="btn-disconnect w-100"
                onClick={handleOpenHubspotLogoutModal}
              >
                Disconnect
              </button>
            ) : (
              <button
                className="btn-agent-bio-save w-100 "
                onClick={handleIntegrationToHubspot}
              >
                Connect
              </button>
            )}
          </div>
          <div className="d-flex-start-start bg-white w-calc-50_minus_1rem b-radius-0_5rem shadow-box-9 p-1rem">
            <div className="d-flex-row-space-between w-100 mb-0_5rem">
              <div className="d-flex-row">
                <div className="d-flex-center mr-0_375rem">
                  <FacebookRoundIcon />
                </div>
                <span className="ff-poppins fw-600 c-111928">Facebook</span>
              </div>
              {/*<div className="d-flex-center icon-hovered">
                <SettingsGearIcon />
              </div>*/}
            </div>
            <span className="ff-poppins fw-400 c-6b7280 fs-0_875rem lh-0_875rem mb-0_5rem">
              Social media platform for ads, customer engagement, and marketing.
            </span>
            {currentUser?.isFacebookLoginIn ? (
              <button
                className="btn-disconnect w-100 "
                onClick={handleOpenFacebookLogoutModal}
              >
                Disconnect
              </button>
            ) : (
              <button
                className="btn-agent-bio-save bg-danger w-100 "
                onClick={handleIntegrationToFacebook}
              >
                Connect
              </button>
            )}
          </div>
        </div>
      </div>
      {showConfirmFacebookLogoutModal && (
        <div className="d-flex-center p-fixed bg-modal top-0 left-0 size-max z4">
          <ConfirmModal
            title="Are you sure you want to remove your Facebook account connection?"
            onConfirm={handleLogoutFromFacebook}
            onCancel={handleCloseFacebookLogoutModal}
          />
        </div>
      )}
      {showConfirmHubspotLogoutModal && (
        <div className="d-flex-center p-fixed bg-modal top-0 left-0 size-max z4">
          <ConfirmModal
            title="Are you sure you want to remove your Hubspot account connection?"
            onConfirm={handleLogoutFromHubspot}
            onCancel={handleCloseHubspotLogoutModal}
          />
        </div>
      )}
      {showSuccessMessage && (
        <div className="d-flex-center p-fixed bg-modal top-0 left-0 size-max z4">
          <InfoModal
            title={showSuccessMessage}
            onClose={handleCloseSuccessModal}
          />
        </div>
      )}
    </div>
  );
};

export default SettingsIntegrations;
