import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAppData } from '../../context/AppContext';
import { LogoSmallPNG, LogoWithTitlePNG } from '../../assets';
import { sidebarConfig } from '../../config/appConfig';

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sidebarState, setSidebarState } = useAppData();
  const [activeItem, setActiveItem] = useState<string>('dashboard');

  const sidebarVisible = sidebarState === 'visible';
  const sidebarHidden = sidebarState === 'hidden';

  const handleItemClick = (itemName: string) => {
    setActiveItem(itemName);
    navigate(`/${itemName}`);
  };

  const handleMouseEnter = () => {
    setSidebarState('visible');
  };

  const handleMouseLeave = () => {
    setSidebarState('minimized');
  };

  useEffect(() => {
    const pathname = location.pathname.slice(1);
    setActiveItem(pathname);
  }, [location.pathname]);

  return (
    <>
      {!sidebarHidden && (
        <div
          className={`p-fixed d-flex-start-start ${
            sidebarVisible ? 'w-17rem' : 'w-4rem'
          } sidebar-container`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {/* Logo Section */}
          <div
            className={`${
              sidebarVisible ? 'd-flex-row pb-0_5rem' : 'd-flex-center'
            } h-4_375rem min-h-4_375rem w-100 pointer bb-1`}
            onClick={() => handleItemClick('dashboard')}
          >
            <div className="sidebar-image-container">
              {sidebarVisible ? (
                <img
                  alt=""
                  src={LogoWithTitlePNG}
                  className={`sidebar-logo-image ${sidebarVisible ? 'visible' : 'hidden'}`}
                />
              ) : (
                <img
                  alt=""
                  src={LogoSmallPNG}
                  className={`sidebar-logo-image ${sidebarVisible ? 'hidden' : 'visible'}`}
                />
              )}
            </div>
          </div>

          <div className="d-flex-start-start w-100">
            {sidebarConfig.map((section) => (
              <div key={section.title}>
                {/* Section Title */}
                {sidebarVisible && (
                  <div className={section.className}>
                    <span className="submenu-title">{section.title}</span>
                  </div>
                )}
                {/* Section Items */}
                {section.items.map((item) => (
                  <div
                    key={item.name}
                    className={`${
                      sidebarVisible
                        ? 'p-1_312rem-0 w-100 pl-1_25rem'
                        : 'p-0_938rem-0 pl-1_25rem'
                    } d-flex-row sidebar-item-container ${
                      activeItem === item.name ? 'active' : ''
                    }`}
                    onClick={() => handleItemClick(item.name)}
                  >
                    {activeItem === item.name && sidebarVisible && (
                      <div className="sidebar-item-active"></div>
                    )}
                    <item.icon className="sidebar-icon" />
                    {sidebarVisible && (
                      <span className="pl-1_25rem sidebar-item-title">
                        {item.label}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
