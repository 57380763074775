import React, { useState, useEffect } from 'react';

//import DashboardSearchbar from './DashboardSearchbar';
import { LeadsTable, PaginationComponent } from '../../leads/components';
import { LeadsFilterStateProps } from '../../../core/types/_models';
import { useAppData } from '../../../context/AppContext';
import { getLeads } from '../../../core/requests/requests';
import { useAuth } from '../../../context/AuthContext';

const DashboardTable: React.FC = () => {
  const { authLogin } = useAuth();
  const { leadsList, setLeadsList } = useAppData();
  const currentUserId = authLogin?.userId;
  const [leadsState, setLeadsState] = useState<LeadsFilterStateProps>({
    showLeadsDropdown: 'All leads',
    sortLeadsDropdown: 'Default',
    filterLeadsTags: [],
    filterLeadsPlatforms: [],
    searchQuery: '',
    actionDropdown: 'Action',
    currentPage: 1,
    itemsPerPage: 10,
  });

  const totalPaginationPages = Math.ceil(
    (leadsList?.length || 1) / leadsState?.itemsPerPage
  );

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        if (currentUserId) {
          const { data } = await getLeads(currentUserId);
          if (data) {
            setLeadsList((prevLeads) => {
              if (!prevLeads) return data; // return the new leads if prevLeads is null or undefined
              const existingIds = new Set(prevLeads.map((lead) => lead.id));
              const uniqueLeads = data.filter(
                (lead) => !existingIds.has(lead.id)
              );
              return [...prevLeads, ...uniqueLeads];
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchLeads();
  }, []);

  // Calculate pagination
  const indexOfLastLead = leadsState?.currentPage * leadsState?.itemsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsState?.itemsPerPage + 1;
  const currentLeads = leadsList?.slice(indexOfFirstLead - 1, indexOfLastLead);

  return (
    <div className="d-flex-start-start w-100 bg-white b-radius-1_25rem mt-1_75rem p-1_125rem-1_625rem">
      {/*<DashboardSearchbar />*/}
      {currentLeads && currentLeads?.length > 0 ? (
        <>
          <LeadsTable leadsList={currentLeads} setLeadsList={setLeadsList} />
          <PaginationComponent
            leadsState={leadsState}
            setLeadsState={setLeadsState}
            totalPaginationPages={totalPaginationPages}
            totalLeadsItems={leadsList?.length || 0}
          />
        </>
      ) : (
        <div className="d-flex-center w-100 p-2rem">
          <span className="ff-inter fs-1_25rem fw-600 c-9ca3af">
            There are no connected leads
          </span>
        </div>
      )}
    </div>
  );
};

export default DashboardTable;
