import React, { useState } from 'react';
import { ConversationFiltersComponentProps } from './conversationModels';
import { getAllChats } from '../../../core/requests/requests';
import { ConversationFiltersProps } from '../../../core/types/_models';
import CampaignNameFilter from './CampaignNameFilter';
import ConversationFilter from './ConversationFilter';

const ConversationFilters: React.FC<ConversationFiltersComponentProps> = ({
  filters,
  setFilters,
  setChats,
}) => {
  const [temporaryFilters, setTemporaryFilters] =
    useState<ConversationFiltersProps>({
      campaignId: filters?.campaignId,
      words: filters?.words,
      conversation: filters?.conversation,
      userName: filters?.userName,
      platforms: filters?.platforms,
      status: filters?.status,
      startDate: filters?.startDate,
      endDate: filters?.endDate,
    });

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setTemporaryFilters((prevFilters) => ({
      ...prevFilters,
      platforms: checked
        ? [...prevFilters.platforms, name]
        : prevFilters.platforms.filter((platform) => platform !== name),
    }));
  };

  const fetchAllChats = async (filters: ConversationFiltersProps) => {
    try {
      const { data } = await getAllChats(filters);
      if (data) {
        setChats(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilter = () => {
    setFilters(temporaryFilters);
    //fetchAllChats(temporaryFilters);
  };

  const handleCancel = async () => {
    setFilters({
      campaignId: [],
      words: [],
      conversation: [],
      userName: [],
      platforms: [],
      status: '',
      startDate: '',
      endDate: '',
    });
    setTemporaryFilters({
      campaignId: filters?.campaignId,
      words: [],
      conversation: [],
      userName: [],
      platforms: [],
      status: filters?.status,
      startDate: filters?.startDate,
      endDate: filters?.endDate,
    });
    fetchAllChats(temporaryFilters);
  };

  return (
    <div className="d-flex-start-start w-20rem max-h-100vh overflow-auto p-sticky pt-2rem">
      <CampaignNameFilter setTemporaryFilters={setTemporaryFilters} />
      <ConversationFilter
        title="Words and Phrases"
        placeholder="Ex: pricing I ''price list''"
        name="words"
        values={temporaryFilters.words}
        setFilters={setTemporaryFilters}
      />
      <ConversationFilter
        title="Conversation Title"
        placeholder="Conversation Title"
        name="conversation"
        values={temporaryFilters.conversation}
        setFilters={setTemporaryFilters}
      />
      <ConversationFilter
        title="Username"
        placeholder="Enter username"
        name="userName"
        setFilters={setTemporaryFilters}
        values={temporaryFilters.userName}
      />
      <div className="d-flex-start-start w-100 p-2rem-0 bb-1">
        <div className="d-flex-row w-100 mb-0_75rem">
          <h4 className="h4-primary">Platforms</h4>
        </div>
        {['Instagram', 'Facebook', 'SMS'].map((platform) => (
          <div className="d-flex-row p-0_625rem-0" key={platform}>
            <input
              className="converstaion-filter-checkbox"
              type="checkbox"
              name={platform}
              checked={temporaryFilters.platforms.includes(platform)}
              onChange={handleCheckboxChange}
            />
            <span className="f-roboto-primary pl-0_75rem fs-0_875rem">
              {platform}
            </span>
          </div>
        ))}
        <div className="d-flex-row w-100 mt-2rem w-100">
          <button
            className="btn-conversation-filter-cancel mr-1rem"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button className="btn-conversation-filter" onClick={handleFilter}>
            Filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConversationFilters;
