import React, { useState, useRef } from 'react';
import { useAuth } from '../../../context/AuthContext';
import {
  getLeads,
  loginToFacebook,
  loginToHubspot,
  syncLeadsFromFacebook,
  syncLeadsFromHubspot,
} from '../../../core/requests/requests';
import { InfoModal } from '../../../components';
import useOutsideClick from '../../../hooks/useOutsideClicks';
import { useAppData } from '../../../context/AppContext';

const SyncLeadsButton: React.FC = () => {
  const { currentUser } = useAuth();
  const { setLeadsList } = useAppData();
  const [showDropdown, setShowDropdown] = useState(false);
  const [infoModalMessage, setInfoModalMessage] = useState<string>('');

  const showDropdownRef = useRef<HTMLDivElement>(null);
  const excludeOutsideClickRef = useRef<HTMLDivElement>(null);

  const updateLeadsList = async () => {
    if (currentUser?.id) {
      try {
        const { data } = await getLeads(currentUser?.id);
        if (data) {
          setLeadsList((prevLeads) => {
            if (!prevLeads) return data; // return the new leads if prevLeads is null or undefined
            const existingIds = new Set(prevLeads.map((lead) => lead.id));
            const uniqueLeads = data.filter(
              (lead) => !existingIds.has(lead.id)
            );
            return [...prevLeads, ...uniqueLeads];
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useOutsideClick(
    showDropdownRef,
    () => setShowDropdown(false),
    excludeOutsideClickRef
  );

  const handleCloseInfoModal = () => {
    setInfoModalMessage('');
  };
  const handleSyncHubspotLeads = async () => {
    if (currentUser?.isHubspotLoginIn) {
      try {
        const { data } = await syncLeadsFromHubspot();
        if (data) {
          updateLeadsList();
          setInfoModalMessage(data?.message);
        }
      } catch (error: any) {
        setInfoModalMessage(error?.response?.data?.message);
        console.log(error);
      }
    } else {
      try {
        if (currentUser?.id) {
          const { data } = await loginToHubspot(currentUser?.id);
          //const { data } = await integrationWithHubspot(currentUser);
          if (data?.url) {
            window.open(data.url, '_blank'); // Open the URL in a new tab
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    setShowDropdown(false);
  };

  const handleSyncFacebookLeads = async () => {
    if (currentUser?.isFacebookLoginIn) {
      try {
        const { data } = await syncLeadsFromFacebook();
        if (data) {
          updateLeadsList();
          setInfoModalMessage('Meta leads have been successfully updated!');
        }
      } catch (error: any) {
        setInfoModalMessage(error?.response?.data?.message);
        console.log(error);
      }
    } else {
      try {
        if (currentUser?.id) {
          const { data } = await loginToFacebook(currentUser?.id);
          //const { data } = await integrationWithHubspot(currentUser);
          if (data?.loginUrl) {
            window.open(data.loginUrl, '_blank'); // Open the URL in a new tab
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    setShowDropdown(false);
  };

  const handleTriggerDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <>
      <div className="d-flex-center ml-1rem p-relative">
        <button
          className="btn-import-leads fw-600"
          onClick={handleTriggerDropdown}
        >
          <span>Sync Leads</span>
        </button>
        {showDropdown && (
          <div
            className="p-absolute left-0 top-2_65rem z1 bg-white w-100  shadow-box-12"
            ref={showDropdownRef}
          >
            <div
              className="d-flex-center-start h-2_375rem bt-1 hovered"
              onClick={handleSyncFacebookLeads}
            >
              <span className="sort-dropdown-item pl-0_75rem">Meta</span>
            </div>
            <div
              className="d-flex-center-start h-2_375rem bt-1 hovered"
              onClick={handleSyncHubspotLeads}
            >
              <span className="sort-dropdown-item pl-0_75rem">Hubspot</span>
            </div>
          </div>
        )}
      </div>
      {infoModalMessage && (
        <div className="d-flex-center p-fixed bg-modal top-0 left-0 size-max z4">
          <InfoModal title={infoModalMessage} onClose={handleCloseInfoModal} />
        </div>
      )}
    </>
  );
};

export default SyncLeadsButton;
