import React, { useState, useEffect } from 'react';
import moment from 'moment';

import {
  ConversationLinearChart,
  DashboardCharts,
  DashboardHeader,
  DashboardTable,
} from './components';
import { DashboardStatisticsProps } from '../../core/types/_models';
import { useAuth } from '../../context/AuthContext';
import { getDashboardStats } from '../../core/requests/requests';

const Dashboard: React.FC = () => {
  const { currentUser } = useAuth();
  const [dashboardStatisticsData, setDashboardStatisticsData] =
    useState<DashboardStatisticsProps | null>(null);
  // Calculate default dashboard query string with moment
  const defaultStartDate = moment().subtract(29, 'days').format('YYYY-MM-DD');
  const defaultEndDate = moment().format('YYYY-MM-DD');
  const [dashboardQueryString, setDashboardQueryString] = useState<string>(
    `?startDate=${defaultStartDate}&endDate=${defaultEndDate}&type=day`
  );

  const userId = currentUser?.id;

  useEffect(() => {
    const fetchDashboardStats = async () => {
      try {
        if (userId) {
          const { data } = await getDashboardStats(
            userId,
            dashboardQueryString
          );
          if (data) {
            setDashboardStatisticsData(data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchDashboardStats();
  }, [dashboardQueryString]);

  return (
    <div className="d-flex-start-start w-100 p-1_875rem">
      <DashboardHeader
        dashboardQueryString={dashboardQueryString}
        setDashboardQueryString={setDashboardQueryString}
      />
      <DashboardCharts
        dashboardStatisticsData={dashboardStatisticsData ?? null}
      />
      <ConversationLinearChart
        dashboardChartData={dashboardStatisticsData?.graphData ?? []}
      />
      <DashboardTable />
    </div>
  );
};

export default Dashboard;
