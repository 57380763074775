import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';

import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from 'chart.js';
import { ConversationChartsProps } from './conversationModels';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const ConversationCharts: React.FC<ConversationChartsProps> = ({
  statisticsData,
}) => {
  const chartData = useMemo(() => {
    const labels = statisticsData.map(
      (data, index, arr) =>
        index === 0 || index === arr.length - 1
          ? moment(data.date).format('YYYY-MM-DD') // Show year on first & last
          : moment(data.date).format('MM-DD') // Hide year on others
    );
    const data = statisticsData.map(
      ({ conversationalLeads }) => conversationalLeads
    );

    return {
      labels,
      datasets: [
        {
          label: 'Conversational Leads',
          data,
          backgroundColor: '#47A3FF',
        },
      ],
    };
  }, [statisticsData]);

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        grid: { display: false },
        ticks: { stepSize: 1 },
      },
    },
    plugins: {
      legend: { display: false },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default ConversationCharts;
