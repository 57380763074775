import React, { useState, useEffect } from 'react';

import {
  exportStatisticsByCampaignId,
  getCampaigns,
} from '../../../core/requests/requests';
import { CampaignDataProps } from '../../../core/types/_models';
import { SelectCampaignModalProps } from './conversationModels';
import { CloseIcon, SearchIcon } from '../../../assets';
import { fileExportTypes } from '../../../config/CampaignsConfig';

const SelectCampaignModal: React.FC<SelectCampaignModalProps> = ({
  exportFileType,
  closeModal,
  chartFilters,
}) => {
  const [campaignId, setCampaignId] = useState<string | null>(null);
  const [searchInput, setSearchInput] = useState<string>('');
  const [filteredCampaigns, setFilteredCampaigns] = useState<
    CampaignDataProps[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [campaignsData, setCampaignsData] = useState<CampaignDataProps[]>([]);

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const { data } = await getCampaigns();
        if (data) {
          setCampaignsData(data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCampaigns();
  }, []);

  const handleCheckboxChange = (selectedCampaignId: string) => {
    if (campaignId === selectedCampaignId) {
      setCampaignId(null); // Uncheck the selected number
    } else {
      setCampaignId(selectedCampaignId); // Check the selected number
    }
  };

  const handleClearInput = () => {
    setSearchInput('');
  };

  const handleConfirm = async () => {
    try {
      if (campaignId) {
        setIsLoading(true);
        const { data } = await exportStatisticsByCampaignId(
          campaignId,
          exportFileType,
          chartFilters?.startDate,
          chartFilters?.endDate
        );

        if (data instanceof Blob) {
          let fileType = '';
          let fileExtension = '';

          if (exportFileType === fileExportTypes.CSV) {
            fileType = 'text/csv';
            fileExtension = '.csv';
          } else if (exportFileType === fileExportTypes.EXCEL) {
            fileType =
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            fileExtension = '.xlsx';
          }

          // Use the received Blob directly for download
          const blob = new Blob([data], { type: fileType });

          // Create a temporary link element for download
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `campaign_stats${fileExtension}`;

          // Trigger the download
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error('Expected Blob but received:', typeof data); // Handle unexpected formats
        }

        // Stop loading and close the modal
        setIsLoading(false);
        closeModal();
      }
    } catch (error) {
      console.error('Error during file export:', error);
      setIsLoading(false); // Ensure loading is stopped in case of an error
    }
  };

  useEffect(() => {
    if (searchInput) {
      setFilteredCampaigns(
        campaignsData.filter((campaign) => {
          const searchLower = searchInput.toLowerCase();
          return campaign?.name?.toLowerCase().includes(searchLower);
        })
      );
    } else {
      setFilteredCampaigns(campaignsData);
    }
  }, [searchInput, campaignsData]);

  return (
    <div className="d-flex-start-between w-30rem max-h-80vh min-h-40vh z4 bg-white b-radius-0_5rem ">
      <div className="d-flex-start-start w-100">
        <div className="d-flex-row-space-between w-100 p-1rem bb-1-l-gray">
          <span className="ff-poppins fw-500 fs-0_875rem c-regular">
            Select Campaign to export conversations:
          </span>
          <div className="d-flex-center icon-hovered" onClick={closeModal}>
            <CloseIcon />
          </div>
        </div>
        <div className="d-flex-start-start w-100">
          <div className="d-flex-center w-100 p-1rem ">
            <div className="d-flex-row w-100 p-relative">
              <input
                className="settings-modal-input"
                placeholder="Search"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <div className="d-flex-center p-absolute top-0_8rem left-1rem">
                <SearchIcon />
              </div>
              {searchInput?.length > 0 ? (
                <div
                  className="d-flex-center p-absolute top-0_8rem right-1rem icon-hovered"
                  onClick={handleClearInput}
                >
                  <CloseIcon />
                </div>
              ) : null}
            </div>
          </div>
          <div className="d-flex-start-start w-100 p-0-1rem max-h-calc-80vh_minus_13rem overflow-auto">
            {filteredCampaigns?.length > 0 &&
              filteredCampaigns.map((campaign) => (
                <div
                  className={`${
                    campaignId !== null && campaignId !== campaign?.id
                      ? 'c-9ca3af'
                      : ''
                  } d-flex-row  w-100 p-1rem bb-1 hovered`}
                  key={campaign?.id}
                  onClick={() => handleCheckboxChange(campaign?.id ?? '')}
                >
                  <div className="d-flex-center mr-1rem">
                    <input
                      type="checkbox"
                      className="converstaion-filter-checkbox"
                      checked={campaignId === campaign?.id}
                    />
                  </div>
                  <span className="ff-inter c-111928 mr-1rem fw-500 fs-0_875rem">
                    {campaign?.name}
                  </span>
                  <span className="mr-1rem ff-inter c-111928  fs-0_875rem">
                    <span className="fw-600">Conversations:</span>{' '}
                    {campaign?.conversationsCount}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="d-flex-row-space-between w-100 p-1rem">
        <button
          className="btn btn-cancel w-10rem h-2_375rem"
          onClick={closeModal}
        >
          <span className="ff-poppins c-black fs-0_75rem fw-600">Close</span>
        </button>
        {isLoading ? (
          <button
            className="btn btn-primary w-17rem h-2_375rem"
            disabled
            onClick={handleConfirm}
          >
            <span className="ff-poppins c-white fs-0_75rem fw-600">
              Generating file
            </span>
          </button>
        ) : (
          <button
            className="btn btn-primary w-17rem h-2_375rem"
            disabled={campaignId === null}
            onClick={handleConfirm}
          >
            <span className="ff-poppins c-white fs-0_75rem fw-600">
              Confirm
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

export default SelectCampaignModal;
