import React, { useState, useRef } from 'react';

import { SelectCampaignDateRangeProps } from './campaignSettingsFormModels';
import { CalendarBlackIcon, ChevronDownIcon } from '../../../../assets';
import DateRangeSelector from '../DateRangeSelector';
import useOutsideClick from '../../../../hooks/useOutsideClicks';
import { formatDate } from '../../../../helpers/dateHelpers';

const SelectCampaignDateRange: React.FC<SelectCampaignDateRangeProps> = ({
  formikValues,
  setFormikValues,
  isCampaignDisabled,
}) => {
  const [isDateRangeSelectorOpen, setIsDateRangeSelectorOpen] = useState(false);
  const dateRangeSelectorRef = useRef<HTMLDivElement>(null);
  const excludeOutsideClickRef = useRef<HTMLDivElement>(null);

  useOutsideClick(
    dateRangeSelectorRef,
    () => setIsDateRangeSelectorOpen(false),
    excludeOutsideClickRef
  );

  const openDateRangeSelector = () => {
    setIsDateRangeSelectorOpen(!isDateRangeSelectorOpen);
  };

  const closeDateRangeSelector = () => {
    setIsDateRangeSelectorOpen(false);
  };

  return (
    <div className="d-flex-start-start w-100 mb-1rem p-relative">
      <label className="campaign-label-input">Duration</label>
      <div
        className={`${isCampaignDisabled ? 'opacity-0_7' : 'pointer'} d-flex-row campaign-input w-100`}
        onClick={() =>
          !isCampaignDisabled ? openDateRangeSelector() : () => {}
        }
        ref={excludeOutsideClickRef}
      >
        <CalendarBlackIcon />
        <span className="pl-0_5rem">
          {`(${formatDate(formikValues?.startDate)}) - (${formatDate(formikValues?.endDate)})`}
        </span>
        <div className="d-flex-center p-absolute right-0_625rem top-2_8rem">
          <ChevronDownIcon
            className={`${isDateRangeSelectorOpen && 't-rotate-180'}`}
          />
        </div>
      </div>
      {isDateRangeSelectorOpen && (
        <div className="d-flex-start-start" ref={dateRangeSelectorRef}>
          <DateRangeSelector
            closeDateRangeSelector={closeDateRangeSelector}
            formikValues={formikValues}
            setFormikValues={setFormikValues}
          />
        </div>
      )}
    </div>
  );
};

export default SelectCampaignDateRange;
