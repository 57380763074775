import React, { useRef } from 'react';
import useOutsideClick from '../../../hooks/useOutsideClicks';
import { CampaignStartedModalProps } from './startCampaignModels';
import { CampaignStartedBackgroundPNG, CloseIcon } from '../../../assets';

const CampaignStartedModal: React.FC<CampaignStartedModalProps> = ({
  title,
  onClose,
}) => {
  const showInfoModalRef = useRef<HTMLDivElement>(null);
  useOutsideClick(showInfoModalRef, () => onClose());

  return (
    <div className="d-flex-center w-100 h-100vh p-absolute top-0 left-0">
      <div
        className="d-flex-start-center w-30rem z4 bg-white p-1rem b-radius-1rem p-relative"
        ref={showInfoModalRef}
      >
        <div
          className="p-absolute top-0_5rem right-0_5rem icon-hovered"
          onClick={onClose}
        >
          <CloseIcon />
        </div>
        <div className="d-flex-center w-100 mt-0_5rem">
          <img
            src={CampaignStartedBackgroundPNG}
            alt=""
            className="w-12_5rem"
          />
        </div>

        <h2 className="text-center ff-poppins fw-600 lh-2_25rem ls-0_0625rem c-171725">
          {title}
        </h2>
        <span className="c-main ff-roboto fs-0_875rem lh-1_5rem text-center ls-0_0625rem pt-0_5rem">
          Check back soon for results
        </span>
        <div className="w-100 d-flex-center mt-1rem">
          <button
            className="btn btn-header-primary w-100 mb-0_5rem"
            onClick={onClose}
          >
            Got it
          </button>
        </div>
      </div>
    </div>
  );
};

export default CampaignStartedModal;
