import {
  AnalyticsIcon,
  AssistantIcon,
  CampaignsIcon,
  ConversationsIcon,
  DashboardIcon,
  LeadsIcon,
  SettingsIcon,
} from '../assets';

export const sidebarConfig = [
  {
    title: 'Main',
    className: 'pt-2_125rem pb-0_375rem pl-1_25rem',
    items: [
      {
        name: 'dashboard',
        label: 'Dashboard',
        icon: DashboardIcon,
      },
      {
        name: 'leads',
        label: 'Leads',
        icon: LeadsIcon,
      },
      {
        name: 'email-assistant',
        label: 'Conversational AI Training',
        icon: AssistantIcon,
      },
      {
        name: 'campaigns',
        label: 'Campaigns',
        icon: CampaignsIcon,
      },
      {
        name: 'conversations',
        label: 'Conversations',
        icon: ConversationsIcon,
      },
      {
        name: 'analytics',
        label: 'Analytics',
        icon: AnalyticsIcon,
      },
    ],
  },
  {
    title: 'Other',
    className: 'pt-1_5rem pb-0_375rem pl-1_25rem',
    items: [
      {
        name: 'settings/agent-bio',
        label: 'Settings',
        icon: SettingsIcon,
      },
    ],
  },
];

export const MAX_FILE_SIZE = 5 * 1024 * 1024;
